// src/components/Navbar.tsx

import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { auth } from './firebaseConfig';
import { useAuthState } from 'react-firebase-hooks/auth';
import logoImage from './assets/logo.png';
import Stepper from './Stepper';

// Styled Components
const NavbarContainer = styled.nav<{ isSticky?: boolean }>`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 0px;
  background-color: #ffffff;
  box-shadow: 0 1.6px 3.2px rgba(0, 0, 0, 0.1);
  z-index: 2;
  ${(props) => props.isSticky && 'position: sticky; top: 0;'}

  @media (max-width: 768px) {
    padding: 12px 24px;
  }
`;

const LeftContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Logo = styled(Link)`
  display: flex;
  align-items: center;
  font-size: 1.44rem;
  font-weight: 700;
  color: black;
  text-decoration: none;
  margin-left: 62px;

  &:hover {
    color: #005b80;
  }

  @media (max-width: 1020px) {
    margin-left: 48px;
  }

  @media (max-width: 480px) {
    margin-left: 0px;
  }

  img {
    margin-right: 8px;
    height: 32px; /* Adjust the height as needed */
  }
`;

const LeftNavLinks = styled.div`
  display: flex;
  align-items: center;
  margin-left: 20px;
`;

const NavLinks = styled.div`
  display: flex;
  align-items: center;
`;

const NavLinkStyled = styled(Link)`
  color: #333;
  text-decoration: none;
  font-size: 0.9rem;
  transition: color 0.3s ease;
  margin-right: 40px;

  &:hover {
    color: #0074a2;
  }

  @media (max-width: 1020px) {
    margin-right: 16px;
  }

  @media (max-width: 480px) {
    margin-right: 10px;
    font-size: 0.81rem;
  }
`;

const NavButton = styled(Link)`
  background-color: transparent;
  color: #333;
  border: 1px solid #333;
  padding: 8px 16px;
  margin-right: 62px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;

  &:hover {
    background-color: #333;
    color: #fff;
  }

  &:focus {
    outline: 1.6px solid #50c878;
    outline-offset: 1.6px;
  }

  @media (max-width: 1020px) {
    margin-right: 32px;
  }

  @media (max-width: 480px) {
    padding: 6.4px 12.8px;
    font-size: 0.72rem;
  }
`;

const SignOutButton = styled.button`
  background-color: transparent;
  color: #333;
  border: 1px solid #333;
  padding: 8px 16px;
  margin-right: 62px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;

  &:hover {
    background-color: #333;
    color: #fff;
  }

  @media (max-width: 1020px) {
    margin-right: 8px;
  }

  @media (max-width: 480px) {
    margin-right: 10px;
  }
`;

// Navbar Props Interface
interface NavbarProps {
  leftNavItems?: Array<{
    label: string;
    to: string;
    onClick?: () => void;
    isButton?: boolean;
  }>;
  navItems?: Array<{
    label: string;
    to: string;
    onClick?: () => void;
    isButton?: boolean;
  }>;
  showSignOutButton?: boolean;
  isSticky?: boolean;
  currentStep?: number;
}

// Navbar Component
const Navbar: React.FC<NavbarProps> = ({
  leftNavItems = [],
  navItems = [],
  showSignOutButton = false,
  isSticky = false,
  currentStep = 0,
}) => {
  const [user] = useAuthState(auth);
  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth > 768);

  useEffect(() => {
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth > 1000);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleSignOut = async () => {
    try {
      await auth.signOut();
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  return (
    <NavbarContainer isSticky={isSticky}>
      <LeftContainer>
        <Logo to="/">
          <img src={logoImage} alt="Logo" />
          PhotoOp
        </Logo>
        <LeftNavLinks>
          {leftNavItems.map((item, index) =>
            item.isButton ? (
              <NavButton key={index} to={item.to} onClick={item.onClick}>
                {item.label}
              </NavButton>
            ) : (
              <NavLinkStyled key={index} to={item.to} onClick={item.onClick}>
                {item.label}
              </NavLinkStyled>
            )
          )}
        </LeftNavLinks>
      </LeftContainer>
      {isLargeScreen && currentStep !== 0 && <Stepper currentStep={currentStep} />}
      <NavLinks>
        {navItems.map((item, index) =>
          item.isButton ? (
            <NavButton key={index} to={item.to} onClick={item.onClick}>
              {item.label}
            </NavButton>
          ) : (
            <NavLinkStyled key={index} to={item.to} onClick={item.onClick}>
              {item.label}
            </NavLinkStyled>
          )
        )}
        {showSignOutButton && user && (
          <SignOutButton onClick={handleSignOut}>Sign Out</SignOutButton>
        )}
      </NavLinks>
    </NavbarContainer>
  );
};

export default Navbar;
