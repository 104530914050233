// CustomModelsPage.tsx

import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth, storage, db } from './firebaseConfig';
import { ref, getDownloadURL, deleteObject } from 'firebase/storage';
import { collection, getDocs, updateDoc, doc } from 'firebase/firestore';
import Navbar from './Navbar';
import { signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import '@fortawesome/fontawesome-free/css/all.min.css'; // Import FontAwesome CSS
import NewModelTrainer from './ModelTrainingComponents/NewModelTrainer';
import ModelQueryUI from './ModelTrainingComponents/ModelQueryUI';
import ModelProgressUI from './ModelTrainingComponents/ModelProgressUI';
import { FaTrash } from 'react-icons/fa';

// Styled Components

// Page Container
const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

// Content Container
const ContentContainer = styled.div`
  flex: 1;
  display: flex;
  background-color: #f9fafb; // Tailwind Gray-50
`;

// Sidebar
const Sidebar = styled.div`
  width: 340px;
  background-color: #ffffff;
  border-right: 1px solid #e5e7eb; // Tailwind Gray-200
  padding: 24px;
  box-sizing: border-box;
  overflow-y: auto;
`;

// Sidebar Title
const SidebarTitle = styled.h2`
  font-size: 1.25rem;
  margin-bottom: 24px;
  font-weight: 600;
  color: #111827; // Tailwind Gray-900
`;

// Model List
const ModelList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

// Model List Item
const ModelListItem = styled.li<{ selected: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
  cursor: pointer;
  padding: 12px;
  border-radius: 6px;
  background-color: ${(props) => (props.selected ? '#e0f2fe' : '#f9fafb')}; // Selected: Blue-50, Default: Gray-50
  border: 1px solid ${(props) => (props.selected ? '#38bdf8' : '#e5e7eb')}; // Selected: Blue-400, Default: Gray-200
  color: #1f2937; // Tailwind Gray-800
  transition: background 0.3s, border 0.3s;

  &:hover {
    background-color: #e0f2fe; // Blue-50
    border-color: #38bdf8; // Blue-400
  }
`;

// Model Info Container
const ModelInfo = styled.div`
  flex: 1;
  margin-right: 8px;
`;

// Model Name (now possibly editable)
const ModelName = styled.span`
  font-size: 1rem;
  font-weight: 500;
`;

// Editable Model Name Input
const ModelNameInput = styled.input`
  font-size: 1rem;
  font-weight: 500;
  width: 100%;
  padding: 4px;
  border: 1px solid #d1d5db; // Gray-300
  border-radius: 4px;

  &:focus {
    outline: none;
    border-color: #2563eb; // Blue-600
  }
`;

// Model Date
const ModelDate = styled.span`
  display: block;
  font-size: 0.875rem;
  color: #6b7280; // Gray-500
  margin-top: 4px;
`;

// Edit Icon Button
const EditIconButton = styled.button`
  background: none;
  border: none;
  color: #6b7280; // Gray-500
  cursor: pointer;
  font-size: 1rem;
  padding: 4px;

  &:hover {
    color: #4b5563; // Gray-600
  }
`;

// Add New Model Button
const AddModelButton = styled.button`
  width: 100%;
  padding: 12px;
  margin-bottom: 16px;
  background-color: #2563eb; // Blue-600
  color: #ffffff;
  border: none;
  border-radius: 6px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: background 0.3s;

  &:hover {
    background-color: #1d4ed8; // Blue-700
  }
`;

// Main Content
const MainContent = styled.div`
  flex: 1;
  padding: 32px;
  display: flex;
  flex-direction: column;
  background-color: #f9fafb; // Gray-50
`;

// Prompt Section
const PromptSection = styled.div`
  margin-bottom: 32px;
`;

// Prompt Title
const PromptTitle = styled.h3`
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 16px;
  color: #111827; // Tailwind Gray-900
`;

// Selected Model Name Display
const SelectedModelName = styled.span`
  color: #2563eb; // Blue-600
`;

// Prompt Textarea
const PromptTextarea = styled.textarea`
  width: 100%;
  height: 150px;
  padding: 16px;
  border: 1px solid #d1d5db; // Gray-300
  border-radius: 8px;
  font-size: 1rem;
  resize: none;
  margin-bottom: 16px;
  background-color: #ffffff;
  color: #111827;
  box-sizing: border-box;

  &:focus {
    outline: none;
    border-color: #2563eb; // Blue-600
    box-shadow: 0 0 0 3px rgba(37, 99, 235, 0.3); // Blue-600 with opacity
  }
`;

// Generate Button
const GenerateButton = styled.button`
  background-color: #10b981; // Emerald-500
  color: #ffffff;
  border: none;
  padding: 14px 28px;
  font-size: 1rem;
  border-radius: 8px;
  cursor: pointer;
  font-weight: 600;
  transition: background 0.3s;
  margin-right: 16px;

  &:hover {
    background-color: #059669; // Emerald-600
  }

  &:disabled {
    background-color: #9ca3af; // Gray-400
    cursor: not-allowed;
  }
`;

// Image Preview Section
const ImagePreview = styled.div`
  margin-top: 32px;
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
`;

// Generated Image
const GeneratedImage = styled.img`
  width: 100%;
  max-width: 300px;
  border: 1px solid #d1d5db; // Gray-300
  border-radius: 8px;
  object-fit: cover;
`;

// Placeholder Text
const PlaceholderText = styled.p`
  font-size: 1rem;
  color: #6b7280; // Gray-500
  margin-top: 16px;
`;

// Sign-In Prompt
const SignInPrompt = styled.div`
  padding: 32px;
  text-align: center;
`;

// Sign-In Button
const SignInButton = styled.button`
  background-color: #2563eb; // Blue-600
  color: white;
  padding: 12px 24px;
  font-size: 1rem;
  font-weight: 600;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  margin-top: 16px;
  transition: background 0.3s;

  &:hover {
    background-color: #1d4ed8; // Blue-700
  }

  i {
    margin-right: 8px;
  }
`;

// Loading Message
const LoadingMessage = styled.p`
  font-size: 1rem;
  color: #6b7280; // Gray-500
  text-align: center;
  margin-top: 32px;
`;

// Error Message
const ErrorMessage = styled.p`
  font-size: 1rem;
  color: #dc2626; // Red-600
  text-align: center;
  margin-top: 32px;
`;

// Styled Components for the Sidebar
const ModelCard = styled.div<{ selected: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 16px;
  margin-bottom: 16px;
  border-radius: 8px;
  background-color: ${(props) => (props.selected ? '#f0f9ff' : '#ffffff')}; // Selected: Blue-50, Default: White
  border: 2px solid ${(props) => (props.selected ? '#38bdf8' : '#e5e7eb')}; // Selected: Blue-400, Default: Gray-200
  box-shadow: ${(props) =>
    props.selected ? '0 4px 6px rgba(56, 189, 248, 0.3)' : '0 2px 4px rgba(0, 0, 0, 0.1)'};
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background-color: #f0f9ff; // Blue-50
    border-color: #38bdf8; // Blue-400
    transform: scale(1.02);
  }
`;

const ModelCardHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
`;

const ModelNameStyled = styled.h3`
  font-size: 1rem;
  font-weight: 600;
  color: #1e293b; // Gray-800
  margin: 0;
`;

const TrainingStatus = styled.span<{ status: string }>`
  font-size: 0.875rem;
  font-weight: 500;
  color: ${(props) =>
    props.status === 'complete'
      ? '#10b981' // Green-500
      : props.status === 'inProgress'
        ? '#f59e0b' // Amber-500
        : '#ef4444'}; // Red-500
  background-color: ${(props) =>
    props.status === 'complete'
      ? '#d1fae5' // Green-100
      : props.status === 'inProgress'
        ? '#fef3c7' // Amber-100
        : '#fee2e2'}; // Red-100
  padding: 4px 8px;
  border-radius: 4px;
`;

const ModelCardBody = styled.div`
  margin-top: 8px;
`;

const TriggerWord = styled.div`
  font-size: 0.875rem;
  color: #6b7280; // Gray-500
  margin-top: 4px;
`;

const ModelActions = styled.div`
  margin-top: 12px;
  display: flex;
  justify-content: flex-end;
  gap: 8px;

  button {
    background-color: #f87171; // Red-400
    color: white;
    border: none;
    padding: 6px 12px;
    font-size: 0.875rem;
    font-weight: 500;
    border-radius: 4px;
    cursor: pointer;
    transition: background 0.3s;

    &:hover {
      background-color: #ef4444; // Red-500
    }

    &:disabled {
      background-color: #fca5a5; // Red-300
      cursor: not-allowed;
    }
  }
`;

const TrashButton = styled.button`
  position: absolute;
  bottom: 16px;
  right: 16px;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.2rem;
  color: #f87171; // Red-400
  transition: color 0.2s;

  &:hover {
    color: #ef4444; // Red-500
  }
`;

// Types
type Model = {
  id: string;
  trainingStatus: string;
  modelName: string;
  storagePath: string;
  timestamp: any; // Could be Firebase Timestamp or Date
  userEmail: string;
  triggerWord: string;
};

// Component
const CustomModelsPage: React.FC = () => {
  const [user, loadingUser, errorUser] = useAuthState(auth);
  const [models, setModels] = useState<Model[]>([]);
  const [selectedModel, setSelectedModel] = useState<Model | null>(null);
  const [prompt, setPrompt] = useState<string>('');
  const [generatedImages, setGeneratedImages] = useState<string[]>([]);
  const [isLoadingModels, setIsLoadingModels] = useState<boolean>(false);
  const [isLoadingImages, setIsLoadingImages] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [isEditingModelId, setIsEditingModelId] = useState<string | null>(null);
  const [editedModelName, setEditedModelName] = useState<string>('');
  const [isAddingNewModel, setIsAddingNewModel] = useState<boolean>(false);

  useEffect(() => {
    if (user) {
      fetchModels();
    }
  }, [user]);

  useEffect(() => {
    if (models.length > 0 && !selectedModel) {
      setSelectedModel(models[0]);
    }
  }, [models]);

  const handleDeleteModel = async (modelId: string, storagePath: string, trainingStatus: string) => {
    if (!user?.email) {
      alert('User email is not available.');
      return;
    }

    console.log("owen here:", storagePath)

    const confirmed = window.confirm('Are you sure you want to delete this model? This action cannot be undone.');
    if (!confirmed) return;

    try {
      // Delete the model from Firestore
      const modelDocRef = doc(db, 'image_models', user.email, 'models', modelId);
      await updateDoc(modelDocRef, {
        isDeleted: true,
      });

      if (trainingStatus !== 'inProgress') {
        // Delete the model weights from Firebase Storage only if training is not in progress
        const storageRef = ref(storage, storagePath);
        await deleteObject(storageRef);
      } else {
        console.log('Training is in progress. Skipping storage deletion.');
      }

      // Update the state to remove the deleted model
      setModels((prevModels) => prevModels.filter((model) => model.id !== modelId));

      alert('Model successfully deleted.');
    } catch (error) {
      console.error('Error deleting model:', error);
      alert('Failed to delete the model. Please try again.');
    }
  };


  // Function to fetch models from Firestore
  const fetchModels = async () => {
    setIsLoadingModels(true);
    setError(null);

    try {
      if (!user?.email) {
        throw new Error('User email is not available.');
      }

      // Access the collection at /image_models/{user_email}/models
      const modelsCollection = collection(db, 'image_models', user.email, 'models');
      const querySnapshot = await getDocs(modelsCollection);

      const userModels: Model[] = [];
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        userModels.push({
          id: doc.id,
          trainingStatus: data.trainingStatus || 'complete',
          modelName: data.modelName,
          storagePath: data.storagePath,
          timestamp: data.timestamp,
          userEmail: data.userEmail,
          triggerWord: data.triggerWord || 'john'
        });
      });

      // Update state with fetched models
      setModels(userModels);
    } catch (error) {
      console.error('Error fetching models:', error);
      setError('Failed to load models.');
    } finally {
      setIsLoadingModels(false);
    }
  };

  // Function to handle image generation
  const handleGenerateImage = async () => {
    if (!user || !prompt.trim() || !selectedModel || !selectedModel.storagePath) {
      alert('You must be signed in, select a model, and provide a prompt to generate images.');
      return;
    }

    try {
      setIsLoadingImages(true);
      setGeneratedImages([]);

      // Fetch the ID token for authenticated user
      const idToken = await user.getIdToken();

      // Cloud Function endpoint
      const endpoint = 'https://us-central1-photoop-28a4c.cloudfunctions.net/call_runpod_endpoint';

      // Payload for the request
      const payload = {
        email: user.email,
        prompt,
        modelStoragePath: selectedModel.storagePath,
      };

      const response = await fetch(endpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${idToken}`,
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Error: ${response.status} - ${errorText}`);
      }

      const responseData = await response.json();
      const imagePath = responseData?.output?.image_path;

      console.log("owen here!!!!")
      console.log(responseData)
      console.log(imagePath)

      // Construct the storage path using email
      const imageRef = ref(storage, imagePath);

      // Initialize variables for retry mechanism
      const maxRetries = 10;
      let attempts = 0;
      let imageUrl = '';

      // Retry loop to get the download URL
      while (attempts < maxRetries) {
        try {
          // Try to get the download URL
          imageUrl = await getDownloadURL(imageRef);
          break; // If successful, exit the loop
        } catch (error: any) {
          if (error.code === 'storage/object-not-found') {
            // Image not yet available, wait and retry
            await new Promise((resolve) => setTimeout(resolve, 5000)); // Wait 2 seconds
            attempts++;
          } else {
            // Other errors
            throw error;
          }
        }
      }

      if (imageUrl) {
        console.log('Setting generatedImages with:', imageUrl);
        setGeneratedImages([imageUrl]);
      } else {
        console.log('took too long to generate image');
        throw new Error('Failed to retrieve generated image.');
      }
    } catch (error) {
      console.error('Error generating images:', error);
      alert('Failed to generate images. Please try again.');
    } finally {
      setIsLoadingImages(false);
    }
  };

  // Function to handle Google Sign-In
  const handleSignIn = async () => {
    const provider = new GoogleAuthProvider();
    try {
      await signInWithPopup(auth, provider);
    } catch (error) {
      console.error('Error during sign-in:', error);
    }
  };

  // Function to handle model name edit
  const handleEditModelName = async (modelId: string) => {
    if (!editedModelName.trim()) {
      alert('Model name cannot be empty.');
      return;
    }

    try {
      if (!user?.email) {
        throw new Error('User email is not available.');
      }

      const modelDocRef = doc(db, 'image_models', user.email, 'models', modelId);
      await updateDoc(modelDocRef, { modelName: editedModelName });

      // Update local state
      setModels((prevModels) =>
        prevModels.map((model) =>
          model.id === modelId ? { ...model, modelName: editedModelName } : model
        )
      );
      setIsEditingModelId(null);
      setEditedModelName('');
    } catch (error) {
      console.error('Error updating model name:', error);
      alert('Failed to update model name. Please try again.');
    }
  };

  // Navbar Navigation Items
  const navItems = [
    {
      label: 'Home',
      to: '/',
    },
  ];

  // Render loading state
  if (loadingUser || isLoadingModels) {
    return (
      <PageContainer>
        <Navbar navItems={navItems} showSignOutButton={true} />
        <LoadingMessage>Loading...</LoadingMessage>
      </PageContainer>
    );
  }

  // Render error state
  if (errorUser || error) {
    return (
      <PageContainer>
        <Navbar navItems={navItems} showSignOutButton={true} />
        <ErrorMessage>An error occurred: {errorUser?.message || error}</ErrorMessage>
      </PageContainer>
    );
  }

  // Render sign-in prompt if user is not authenticated
  if (!user) {
    return (
      <PageContainer>
        <Navbar />
        <SignInPrompt>
          <h2>Please Sign In to Access Your Models</h2>
          <SignInButton onClick={handleSignIn}>
            <i className="fab fa-google"></i> Sign in with Google
          </SignInButton>
        </SignInPrompt>
      </PageContainer>
    );
  }

  // Render main content
  return (
    <PageContainer>
      <Navbar navItems={navItems} showSignOutButton={true} />
      <ContentContainer>
        <Sidebar>
          <SidebarTitle>Your Models</SidebarTitle>
          <AddModelButton
            onClick={() => {
              setIsAddingNewModel(true);
              setSelectedModel(null);
            }}
          >
            + Add New Model
          </AddModelButton>
          {models.length > 0 ? (
            <ModelList>
              {models.map((model) => (
                <ModelCard
                  key={model.id}
                  selected={selectedModel?.id === model.id}
                  onClick={() => {
                    setSelectedModel(model);
                    setIsAddingNewModel(false);
                  }}
                >
                  <ModelCardHeader>
                    <ModelNameStyled>{model.modelName}</ModelNameStyled>
                    <TrainingStatus status={model.trainingStatus}>
                      {model.trainingStatus === 'complete' ? 'Ready to use' : 'In Progress'}
                    </TrainingStatus>
                  </ModelCardHeader>
                  <ModelCardBody>
                    <TriggerWord>
                      <strong>Trigger Word:</strong> {model.triggerWord || 'John'}
                    </TriggerWord>
                  </ModelCardBody>
                  <TrashButton
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDeleteModel(model.id, model.storagePath, model.trainingStatus);
                    }}
                  >
                    <FaTrash />
                  </TrashButton>
                </ModelCard>

              ))}
            </ModelList>
          ) : (
            <PlaceholderText>You have no models. Please train a model first.</PlaceholderText>
          )}
        </Sidebar>


        {/* Main Content */}
        <MainContent>
          {isAddingNewModel || models.length === 0 ? (
            <NewModelTrainer
              user={user!}
              onModelAdded={(newModel) => {
                setModels((prevModels) => [...prevModels, newModel]);
                setSelectedModel(newModel);
                setIsAddingNewModel(false);
              }}
            />
          ) : selectedModel ? (
            selectedModel.trainingStatus === 'inProgress' ? (
              <ModelProgressUI selectedModel={selectedModel} />
            ) : (
              <ModelQueryUI
                selectedModel={selectedModel}
                prompt={prompt}
                setPrompt={setPrompt}
                handleGenerateImage={handleGenerateImage}
                generatedImages={generatedImages}
                isLoadingImages={isLoadingImages}
              />
            )
          ) : (
            <PlaceholderText>Select a model to start generating images.</PlaceholderText>
          )}
        </MainContent>
      </ContentContainer>
    </PageContainer>
  );
};

export default CustomModelsPage;
