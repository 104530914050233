// src/firebaseConfig.ts

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {
  initializeFirestore,
  persistentLocalCache,
} from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getAuth } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDXw_9-i68gAMAA8LI8n0L_1dr8Y86kSKE",
  authDomain: "photoop-28a4c.firebaseapp.com",
  projectId: "photoop-28a4c",
  storageBucket: "photoop-28a4c.appspot.com",
  messagingSenderId: "410979486193",
  appId: "1:410979486193:web:852d033e9b6d512f365c50",
  measurementId: "G-7KX59Q2KZE"
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);

// Initialize Analytics
const analytics = getAnalytics(firebaseApp);

// Initialize Firestore with local cache
const db = initializeFirestore(firebaseApp, {
  localCache: persistentLocalCache(),
});

// Initialize Firebase Storage
const storage = getStorage(firebaseApp);

// Initialize Firebase Authentication
export const auth = getAuth(firebaseApp);

export { db, storage, analytics };
