// src/components/OrderExample.tsx

import React, { useEffect, useState } from 'react';
import { ref, listAll, getDownloadURL } from 'firebase/storage';
import styled from 'styled-components';
import { storage } from './firebaseConfig';
import Navbar from './Navbar';

// Styled components
const Container = styled.div`
  padding: 40px;
`;

const Title = styled.h1`
  font-size: 2.56rem; /* Reduced by 20% */
  color: #111;
  margin-bottom: 16px; /* Reduced by 20% */
  line-height: 1.2;

  @media (max-width: 1024px) {
    font-size: 2.24rem; /* Reduced by 20% */
  }

  @media (max-width: 768px) {
    font-size: 1.92rem; /* Reduced by 20% */
  }

  @media (max-width: 480px) {
    font-size: 1.76rem; /* Reduced by 20% */
  }
`;

const SectionTitle = styled.h2`
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 15px;
`;

const DynamicText = styled.span`
  color: #007bff; /* Choose a color that stands out */
  font-weight: bold;
  /* You can add more styles like font-style, text-decoration, etc. */
`;

const ImageGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
  gap: 20px;
  margin-bottom: 30px;

  @media (max-width: 480px) {
    grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
  }
`;

const Image = styled.img`
  width: 100%;
  height: auto;
  border-radius: 10px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
  object-fit: cover;
`;

const ErrorMessage = styled.p`
  font-size: 1rem;
  color: red;
  text-align: center;
`;

const LoadingMessage = styled.p`
  font-size: 1rem;
  color: #555;
  text-align: center;
`;

// Component
const OrderExample: React.FC = () => {
    const [uploadedImages, setUploadedImages] = useState<string[]>([]);
    const [creativeProfessionalImages, setCreativeProfessionalImages] = useState<string[]>([]);
    const [formalHeadshotImages, setFormalHeadshotImages] = useState<string[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [fetchError, setFetchError] = useState('');

    useEffect(() => {
        const fetchImages = async () => {
            const orderId = '37b5e754-5538-410e-8bae-f6ed83b08a59';

            try {
                // Fetch uploaded photos
                const uploadedRef = ref(storage, `uploaded_photos/${orderId}`);
                const uploadedList = await listAll(uploadedRef);
                const uploadedUrls = await Promise.all(
                    uploadedList.items.map((fileRef) => getDownloadURL(fileRef))
                );
                setUploadedImages(uploadedUrls);

                // Fetch generated photos
                const generatedRef = ref(storage, `user_outputs/${orderId}`);
                const generatedList = await listAll(generatedRef);
                const generatedUrls = await Promise.all(
                    generatedList.items.map((fileRef) =>
                        getDownloadURL(fileRef).then((url) => ({
                            url,
                            name: fileRef.name,
                        }))
                    )
                );

                // Categorize images
                const creativeImages = generatedUrls
                    .filter((file) => file.name.includes('linkedin-3'))
                    .map((file) => file.url);
                const formalImages = generatedUrls
                    .filter((file) => file.name.includes('linkedin-1'))
                    .map((file) => file.url);

                setCreativeProfessionalImages(creativeImages);
                setFormalHeadshotImages(formalImages);
            } catch (error) {
                console.error('Error fetching images:', error);
                setFetchError('Failed to fetch images from Firebase Storage.');
            } finally {
                setIsLoading(false);
            }
        };

        fetchImages();
    }, []);

    return (
        <>
            <Navbar
                leftNavItems={[
                    {
                        label: 'Back',
                        to: '/select-template',
                        isButton: true
                    },
                ]}
            />
            <Container>
                <Title>Example Order</Title>
                {isLoading && <LoadingMessage>Loading images...</LoadingMessage>}
                {fetchError && <ErrorMessage>{fetchError}</ErrorMessage>}

                {!isLoading && !fetchError && (
                    <>
                        {/* Uploaded Photos Section */}
                        <SectionTitle>Uploaded Photos</SectionTitle>
                        {uploadedImages.length > 0 ? (
                            <ImageGrid>
                                {uploadedImages.map((url, index) => (
                                    <Image key={index} src={url} alt={`Uploaded Photo ${index + 1}`} />
                                ))}
                            </ImageGrid>
                        ) : (
                            <LoadingMessage>No uploaded photos found.</LoadingMessage>
                        )}


                        {/* Formal Headshot Section */}
                        <SectionTitle>Order Pack: <DynamicText>Formal Headshot</DynamicText></SectionTitle>
                        {formalHeadshotImages.length > 0 ? (
                            <ImageGrid>
                                {formalHeadshotImages.map((url, index) => (
                                    <Image key={index} src={url} alt={`Formal Headshot ${index + 1}`} />
                                ))}
                            </ImageGrid>
                        ) : (
                            <LoadingMessage>No images found for Formal Headshot.</LoadingMessage>
                        )}

                        {/* Creative Professional Section */}
                        <SectionTitle>Order Pack: <DynamicText>Creative Professional</DynamicText></SectionTitle>
                        {creativeProfessionalImages.length > 0 ? (
                            <ImageGrid>
                                {creativeProfessionalImages.map((url, index) => (
                                    <Image key={index} src={url} alt={`Creative Professional ${index + 1}`} />
                                ))}
                            </ImageGrid>
                        ) : (
                            <LoadingMessage>No images found for Creative Professional.</LoadingMessage>
                        )}
                    </>
                )}
            </Container>
        </>
    );
};

export default OrderExample;
