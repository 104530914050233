// src/components/LandingPage.tsx

import React, { useEffect } from 'react';
import styled, { createGlobalStyle, keyframes } from 'styled-components';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { analytics } from './firebaseConfig';
import { logEvent } from 'firebase/analytics';
import Navbar from './Navbar';
import { FaStar, FaStarHalfAlt } from 'react-icons/fa';
import BeforeAndAfterCarousel from './BeforeAndAfterCarousel';
import { FaRobot, FaBolt, FaBrain, FaBullhorn } from 'react-icons/fa';
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "./firebaseConfig";

import owenBefore from './assets/beforeAfter/owenBefore.png';
import gopalImage from './assets/gopal.png';

const Banner = styled.div`
  background: linear-gradient(90deg, #4f46e5 0%, #3b82f6 100%); /* Indigo to Blue gradient */
  color: #ffffff;
  padding: 20px 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: none;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  // border-radius: 0 0 8px 8px; /* Rounded bottom corners */

  @media (max-width: 768px) {
    display: none; 
  }
`;

const BannerText = styled.span`
  font-size: 1.1rem;
  font-weight: 600;
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    margin-bottom: 12px;
    justify-content: center;
  }

  svg {
    margin-right: 8px;
    font-size: 1.5rem;
    color: #fbbf24; /* Amber-400 */
  }
`;

const BannerButton = styled(Link)`
  background: #fbbf24; /* Amber-400 */
  color: #1f2937; /* Gray-800 */
  padding: 12px 24px;
  font-size: 1rem;
  font-weight: 600;
  text-decoration: none;
  border-radius: 30px;
  transition: background 0.3s ease, transform 0.1s ease;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background: #f59e0b; /* Amber-500 */
  }

  &:active {
    transform: scale(0.98);
  }

  &:focus {
    outline: 2px solid #fbbf24;
    outline-offset: 2px;
  }
`;


const RatingDisplay = styled.div`
  display: flex;
  align-items: center;
  margin-left: 16px;

  @media (max-width: 1024px) {
    justify-content: center;
    margin-left: 0px;
  }
`;

const StarsContainer = styled.div`
  display: flex;
  align-items: center;
`;

const StarBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  background-color: #28a745; /* Professional green background */
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  margin-right: 6px;
`;

const StarIcon = styled.span`
  color: #fff; /* White color for the stars */
  font-size: 0.8rem;
  padding-top: 1.5px;
`;

const RatingText = styled.span`
  margin-left: 12px;
  font-size: 0.9rem;
  color: #333;
  font-weight: 500;
`;

const ReviewsText = styled.span`
  font-size: 0.8rem;
  color: #555;
  margin-left: 4px;
  font-weight: 400;
`;

// Global Styles
const GlobalStyle = createGlobalStyle`
  body, html {
    margin: 0;
    padding: 0;
    font-family: 'Inter', sans-serif;
    overflow-x: hidden;
    background-color: #ffffff;
  }
`;

// Keyframes for animations
const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(16px); /* Reduced by 20% */
  } 
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

// Styled component for the landing page container
const LandingContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const CarouselContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-basis: 33.33%
`;

// Hero Section
const HeroSection = styled.section`
  display: flex;
  flex: 1;
  padding: 80px 64px; /* Reduced by 20% */
  padding-bottom: 0px;
  align-items: start;
  animation: ${fadeIn} 1s ease-in;
  background: linear-gradient(135deg, #e0f7fa 0%, #ffffff 100%);
  min-height: 80vh; /* Make the hero section cover 95% of the viewport height */

  @media (max-width: 1024px) {
    flex-direction: column;
    padding: 40px 48px; /* Reduced by 20% */
    text-align: center;
    align-items: center
  }

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center
    padding: 32px 24px; /* Reduced by 20% */
  }

  @media (max-width: 480px) {
    flex-direction: column;
    align-items: center
    padding: 24px 16px; /* Reduced by 20% */
  }
`;

// Content Wrapper
const ContentWrapper = styled.div`
  flex: 1;
  margin-right: 48px; /* Reduced by 20% */
  flex-basis: 66.66%

  @media (max-width: 1024px) {
    margin-right: 0;
    margin-bottom: 32px; /* Reduced by 20% */
    text-align: center;
  }

  @media (max-width: 480px) {
    margin-right: 0px;
  }
`;

const SubHeader = styled.h3`
  font-size: 1.2rem;
  color: #6c757d;
  margin-bottom: 16px;

  @media (max-width: 480px) {
    font-size: 1rem;
  }
`;

const Title = styled.h1`
  font-size: 2.56rem; /* Reduced by 20% */
  color: #111;
  margin-bottom: 16px; /* Reduced by 20% */
  line-height: 1.2;

  @media (max-width: 1024px) {
    font-size: 2.24rem; /* Reduced by 20% */
  }

  @media (max-width: 768px) {
    font-size: 1.92rem; /* Reduced by 20% */
  }

  @media (max-width: 480px) {
    font-size: 1.76rem; /* Reduced by 20% */
  }
`;

const DynamicText = styled.span`
  color: #007bff; /* Choose a color that stands out */
  font-weight: bold;
  /* You can add more styles like font-style, text-decoration, etc. */
`;

const Description = styled.p`
  font-size: 1.1rem;
  color: #495057;
  margin-bottom: 72px;
  line-height: 1.6;

  @media (max-width: 480px) {
    font-size: 1rem;
    margin-bottom: 16px;
  }
`;

const StartButton = styled(Link)`
  background: #007bff;
  color: white;
  padding: 16px 32px;
  font-size: 1.2rem;
  font-weight: 600;
  text-decoration: none;
  border-radius: 50px;
  transition: background 0.3s ease, transform 0.1s ease;
  border: none;
  display: inline-flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 32px;
  outline: 2px solid #0056b3;
  outline-offset: 2px;

  &:hover {
    background: #0056b3;
  }

  &:active {
    transform: scale(0.98);
  }

  &:focus {
    outline: 2px solid #0056b3;
    outline-offset: 2px;
  }

  @media (max-width: 480px) {
    padding: 12px 30px;
    font-size: 1rem;
  }
`;

const StartButtonIcon = styled(FaBolt)`
  color: white;
  font-size: 1rem;
`;

// Technology Highlights Section
const TechnologySection = styled.section`
  // padding: 60px 80px;
  background-color: #f9f9f9;
  text-align: center;
  background: linear-gradient(135deg, #e0f7fa 0%, #ffffff 50%, #f3e5f5 100%);
  border-top: 0.9px solid #b3b3b3;

  padding-bottom: 80px;

  @media (max-width: 768px) {
    padding: 40px 20px;
  }
`;

const SectionTitle = styled.h2`
  font-size: 2rem;
  margin-bottom: 20px;
  color: #111;
  margin-top: 60px;
`;

const TechDescription = styled.p`
  font-size: 1.1rem;
  color: #495057;
  margin-bottom: 40px;
  line-height: 1.6;

  @media (max-width: 480px) {
    font-size: 1rem;
  }
`;

const FeaturesGrid = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 40px;
  margin-top: 80px;
`;

const FeatureItem = styled.div`
  flex: 1 1 200px;
  max-width: 300px;
  text-align: center;
  transition: transform 0.3s ease;

  &:hover {
    transform: translateY(-10px);
  }
`;

const FeatureIcon = styled.div`
  font-size: 3rem;
  color: #007bff;
  margin-bottom: 20px;
`;

const FeatureTitle = styled.h3`
  font-size: 1.2rem;
  margin-bottom: 10px;
  color: #111;
`;

const FeatureText = styled.p`
  font-size: 1rem;
  color: #495057;
`;

// FAQ Section
const FAQSection = styled.section`
  padding: 60px 80px;
  background-color: #f9f9f9;
  text-align: left;
  background: linear-gradient(135deg, #e0f7fa 0%, #ffffff 50%, #e8f5e9 100%);

  @media (max-width: 768px) {
    padding: 40px 20px;
  }
`;

const FAQTitle = styled.h2`
  font-size: 2rem;
  margin-bottom: 40px;
  color: #111;
  text-align: center;
`;

const FAQItem = styled.div`
  margin-bottom: 48px;
`;

const Question = styled.h4`
  font-size: 1.2rem;
  color: #007bff;
  margin-bottom: 10px;
`;

const Answer = styled.p`
  font-size: 1rem;
  color: #495057;
  line-height: 1.6;
`;

// Footer
const Footer = styled.footer`
  padding: 16px 48px; /* Reduced by 20% */
  background: #f7f7f7;
  display: flex;
  justify-content: space-between;
  z-index: 2;
  border-top: 0.9px solid #b3b3b3;

  @media (max-width: 768px) {
    padding: 12px 24px; /* Reduced by 20% */
    flex-direction: column;
    align-items: center;
  }
`;

const FooterText = styled.p`
  color: #777;
  font-size: 0.72rem; /* Reduced by 20% */

  @media (max-width: 768px) {
    margin-bottom: 8px; /* Reduced by 20% */
  }
`;

// LandingPage Component
const LandingPage: React.FC = () => {
  const [user, loading] = useAuthState(auth);

  // Log 'viewed the page' event
  useEffect(() => {
    if (analytics) {
      logEvent(analytics, 'landing_page_viewed', {
        page: 'LandingPage',
      });
    }
  }, []);

  // Handle 'Get Started' click
  const handleGetStartedClick = () => {
    if (analytics) {
      logEvent(analytics, 'get_started_clicked');
    }
  };

  // Handle ImageGallery click (also counts as 'Get Started' click)
  const handleImageGalleryClick = () => {
    if (analytics) {
      logEvent(analytics, 'get_started_clicked');
    }
  };

  return (
    <>
      <Helmet>
        {/* Existing SEO Meta Tags */}
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="anonymous" />
        <link
          href="https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&display=swap"
          rel="stylesheet"
        />
      </Helmet>
      <LandingContainer>
        <Navbar
          navItems={[
            {
              label: 'Your Orders',
              to: '/orders',
              onClick: handleGetStartedClick,
              isButton: true,
            },
          ]}
          isSticky={true}
        />

        {/* SEO Meta Tags */}
        <Helmet>
          <title>Experience Cutting-Edge AI Portraits with PhotoOp</title>
          <meta
            name="description"
            content="Transform your photos into realistic portraits using PhotoOp's cutting-edge AI technology. Experience the future of photography today."
          />
          <meta
            name="keywords"
            content="AI portraits, AI-generated art, photo transformation, AI photo editor, AI art generator, PhotoOp, cutting-edge AI technology"
          />
          {/* Open Graph Meta Tags */}
          <meta property="og:title" content="Experience Cutting-Edge AI Portraits with PhotoOp" />
          <meta
            property="og:description"
            content="Transform your photos into realistic portraits using PhotoOp's cutting-edge AI technology. Experience the future of photography today."
          />
          <meta property="og:image" content="https://photoop.shop/images/ai-portrait-example.jpg" />
          <meta property="og:url" content="https://photoop.shop/" />
          <meta property="og:type" content="website" />
          {/* Twitter Card Meta Tags */}
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content="Experience Cutting-Edge AI Portraits with PhotoOp" />
          <meta
            name="twitter:description"
            content="Transform your photos into realistic portraits using PhotoOp's cutting-edge AI technology. Experience the future of photography today."
          />
          <meta name="twitter:image" content="https://photoop.shop/images/ai-portrait-example.jpg" />
        </Helmet>

        <Banner>
          <BannerText>
            <FaBullhorn />
            New! Custom Prompts Feature is now in Beta.
          </BannerText>
          <BannerButton to="/custom-models" aria-label="Try Custom Prompts">
            Try it Out
          </BannerButton>
        </Banner>

        {/* Hero Section */}
        <HeroSection>
          {/* Main Content */}
          <ContentWrapper>
            <SubHeader>Experience the best AI has to offer</SubHeader>
            <Title>
              Create Realistic Portraits with <DynamicText>Cutting-Edge AI</DynamicText> Technology
            </Title>
            <Description>
              Upload your photos and let our AI generate amazing portraits that showcase the best version of you.
              Experience the future of photography today.
            </Description>
            <StartButton to="/select-template" aria-label="Get Started" onClick={handleGetStartedClick}>
              Get started for free
              {/* <StartButtonIcon /> */}
            </StartButton>
            <RatingDisplay>
              <StarsContainer>
                <StarBox><StarIcon><FaStar /></StarIcon></StarBox>
                <StarBox><StarIcon><FaStar /></StarIcon></StarBox>
                <StarBox><StarIcon><FaStar /></StarIcon></StarBox>
                <StarBox><StarIcon><FaStar /></StarIcon></StarBox>
                <StarBox><StarIcon><FaStarHalfAlt /></StarIcon></StarBox>
              </StarsContainer>
              <RatingText>
                4.8 out of 5 stars <ReviewsText>(122 reviews)</ReviewsText>
              </RatingText>
            </RatingDisplay>
          </ContentWrapper>

          <CarouselContainer>
            <BeforeAndAfterCarousel></BeforeAndAfterCarousel>
          </CarouselContainer>

        </HeroSection>

        <AboutUsSectionComponent />

        {/* Technology Highlights Section */}
        <TechnologySection>
          <SectionTitle>How our AI works</SectionTitle>
          <TechDescription>
            We utilize state-of-the-art AI image models and fine tune them to specifically generate stunning, personalized portraits.
          </TechDescription>
          <FeaturesGrid>
            <FeatureItem>
              <FeatureIcon><FaBrain /></FeatureIcon>
              <FeatureTitle>Advanced Base Models</FeatureTitle>
              <FeatureText>We start with base AI image generation models from the top research institutes in the world</FeatureText>
            </FeatureItem>
            <FeatureItem>
              <FeatureIcon><FaBolt /></FeatureIcon>
              <FeatureTitle>Personalized Fine-Tuning</FeatureTitle>
              <FeatureText>Our base models are trained on your photos to create a custom AI image generation model just for you</FeatureText>
            </FeatureItem>
            <FeatureItem>
              <FeatureIcon><FaRobot /></FeatureIcon>
              <FeatureTitle>Stunning Life-Like Portraits</FeatureTitle>
              <FeatureText>Our custom python scripts use your personal AI model to generate your portraits</FeatureText>
            </FeatureItem>
          </FeaturesGrid>
        </TechnologySection>


        {/* FAQ Section */}
        <FAQSection>
          <FAQTitle>Frequently Asked Questions</FAQTitle>
          <FAQItem>
            <Question>What happens to the photos I upload?</Question>
            <Answer>
              We use your photos solely to process your order. We delete all of your uploaded photos as soon as your order is complete. It is never kept or sold.
            </Answer>
          </FAQItem>
          <FAQItem>
            <Question>What sort of image quality can I expect?</Question>
            <Answer>
              Our AI produces multiple photos per order, with quality varying from highly realistic to experimental. For best results, upload clear, well-lit, high-resolution photos.
            </Answer>
          </FAQItem>
          <FAQItem>
            <Question>Is my data safe?</Question>
            <Answer>
              Absolutely. All of your private information is stored behind layers of security backed by Google.
            </Answer>
          </FAQItem>
          <FAQItem>
            <Question>How does the AI technology work?</Question>
            <Answer>
              Our AI uses advanced deep learning models trained on a vast dataset to generate realistic portraits based on your photos.
            </Answer>
          </FAQItem>
          {/* Add more FAQs as needed */}
        </FAQSection>

        {/* Footer */}
        <Footer>
          <FooterText>&copy; {new Date().getFullYear()} PhotoOp. All rights reserved.</FooterText>
        </Footer>
      </LandingContainer >
    </>
  );
};

// About Us Section
const AboutUsSection = styled.section`
  padding: 60px 80px;
  background-color: #ffffff;
  text-align: center;
  border-top: 1px solid #e0e0e0;
  background: linear-gradient(135deg, #e0f7fa 0%, #ffffff 100%);

  @media (max-width: 768px) {
    padding: 40px 20px;
  }
`;

const AboutUsTitle = styled.h2`
  font-size: 2rem;
  margin-bottom: 20px;
  margin-top: -20px;
  color: #111;
`;

const AboutUsDescription = styled.p`
  font-size: 1.1rem;
  color: #495057;
  margin-bottom: 40px;
  line-height: 1.6;

  @media (max-width: 480px) {
    font-size: 1rem;
  }
`;

const TeamGrid = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 40px;
  margin-top: 40px;
`;

const TeamMember = styled.div`
  flex: 1 1 300px;
  max-width: 300px;
  text-align: center;
`;

const MemberImage = styled.img`
  width: 120px;
  height: 120px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 16px;
  border: 4px solid #007bff;
`;

const MemberName = styled.h3`
  font-size: 1.2rem;
  color: #111;
  margin-bottom: 8px;
`;

const MemberRole = styled.p`
  font-size: 1rem;
  color: #6c757d;
  margin-bottom: 16px;
`;

const MemberDescription = styled.p`
  font-size: 0.9rem;
  color: #495057;
  line-height: 1.4;
`;

const MemberLinkedIn = styled.a`
  color: #007bff;
  text-decoration: none;
  font-weight: 600;
  transition: color 0.2s ease;

  &:hover {
    color: #0056b3;
  }
`;

const AboutUsSectionComponent = () => (
  <AboutUsSection>
    <AboutUsTitle>About Us</AboutUsTitle>
    <AboutUsDescription>
      We're a team based out of New York City with a passion for the practical applications of cutting edge AI. All profits are fed back into the site and infrastructure to improve the quality of our product.
      Please reach out to us with any questions or concerns!
    </AboutUsDescription>
    <TeamGrid>
      <TeamMember>
        <MemberImage src={owenBefore} alt="Owen Khoury" />
        <MemberName>Owen Khoury</MemberName>
        <MemberRole>Founder & CEO</MemberRole>
        <MemberDescription>
          Owen is a tech enthusiast and entrepreneur passionate about AI and its real-world applications. He brings years of experience in building scalable systems and delivering innovative products.
        </MemberDescription>
        <MemberLinkedIn href="https://www.linkedin.com/in/owen-khoury-149019b3/" target="_blank">
          Connect on LinkedIn
        </MemberLinkedIn>
      </TeamMember>
      <TeamMember>
        <MemberImage src={gopalImage} alt="Gopal Hari" />
        <MemberName>Gopal Hari</MemberName>
        <MemberRole>CTO & Head of AI</MemberRole>
        <MemberDescription>
          Gopal is a seasoned engineer with a deep understanding of AI and machine learning. With a track record of building advanced AI models, they ensure PhotoOp stays ahead of the curve.
        </MemberDescription>
        <MemberLinkedIn href="https://www.linkedin.com/in/gopal-hari-a82a63b4/" target="_blank">
          Connect on LinkedIn
        </MemberLinkedIn>
      </TeamMember>
    </TeamGrid>
  </AboutUsSection>
);


export default LandingPage;
