import React, { useState } from 'react';
import styled from 'styled-components';
import { addDoc, collection, updateDoc } from 'firebase/firestore';
import { db, storage } from '../firebaseConfig';
import { ref, uploadBytes } from 'firebase/storage';
import { FiUser, FiHeart, FiCamera, FiInfo } from 'react-icons/fi';

const TrainerContainer = styled.div`
  padding: 28px;
  color: #e0e7ff;
  display: flex;
  flex-direction: column;
  gap: 28px;
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
  font-family: 'Inter', sans-serif;
  max-height: 100vh;
  overflow-y: auto;

  @media (max-width: 1500px) {
    max-width: 1000px;
    gap: 24px;
  }

  @media (max-width: 768px) {
    padding: 16px;
  }
`;

const Title = styled.h2`
  font-size: 2.5rem;
  font-weight: 800;
  text-align: left;
  color: #38bdf8;
  letter-spacing: 0.05rem;

  @media (max-width: 1500px) {
    font-size: 2.25rem;
    margin-bottom: 16px;
  }

  @media (max-width: 768px) {
    font-size: 2rem;
    text-align: center;
  }
`;

const IconSection = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;

  div {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 6px;
    text-align: center;
  }

  svg {
    width: 48px;
    height: 48px;
    color: #38bdf8;
    transition: transform 0.3s;

    &:hover {
      transform: scale(1.1);
    }
  }

  span {
    font-size: 1rem;
    color: #94a3b8;
    font-weight: 500;
  }

  @media (max-width: 1500px) {
    svg {
      width: 38px;
      height: 38px;
    }

    span {
      font-size: 0.85rem;
    }

    div {
      gap: 4px;
    }
  }

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 16px;
  }
`;

const DescriptionWithIcon = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 10px;
  font-size: 0.875rem;
  color: #94a3b8;
  margin-bottom: 20px;

  svg {
    flex-shrink: 0;
    width: 24px;
    height: 24px;
    color: #38bdf8;
  }

  @media (max-width: 1500px) {
    font-size: 0.8rem;
    gap: 8px;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    font-size: 0.75rem;
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(140px, 1fr));
  gap: 20px;

  @media (max-width: 1500px) {
    grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
    gap: 16px;
  }

  @media (max-width: 768px) {
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  }
`;

const PlaceholderBox = styled.div`
  width: 100%;
  height: 140px;
  border: 2px dashed #94a3b8;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #94a3b8;
  font-size: 1rem;
  font-weight: 500;
  position: relative;
  transition: border-color 0.3s, transform 0.3s;

  &:hover {
    border-color: #38bdf8;
    transform: scale(1.02);
  }

  input {
    opacity: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }

  @media (max-width: 1500px) {
    height: 120px;
  }
`;

const PreviewImage = styled.div`
  position: relative;
  border-radius: 12px;
  overflow: hidden;
  height: 140px;
  background-color: #1e293b;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  button {
    position: absolute;
    top: 8px;
    right: 8px;
    background: rgba(255, 255, 255, 0.8);
    color: #111827;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    width: 28px;
    height: 28px;
    font-size: 0.75rem;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background 0.3s;

    &:hover {
      background: rgba(255, 0, 0, 0.8);
      color: white;
    }
  }

  @media (max-width: 1500px) {
    height: 120px;
  }
`;

const InputField = styled.input`
  width: 100%;
  padding: 14px;
  border: 1px solid #94a3b8;
  border-radius: 8px;
  font-size: 1rem;
  outline: none;
  transition: border-color 0.3s, box-shadow 0.3s;

  &:focus {
    border-color: #38bdf8;
    box-shadow: 0 0 8px rgba(56, 189, 248, 0.5);
  }

  @media (max-width: 1500px) {
    padding: 12px;
    font-size: 0.9rem;
  }

  @media (max-width: 768px) {
    font-size: 0.875rem;
  }
`;

const SubmitButton = styled.button<{ disabled: boolean }>`
  background: ${(props) => (props.disabled ? '#334155' : '#38bdf8')};
  color: ${(props) => (props.disabled ? '#94a3b8' : 'white')};
  border: none;
  padding: 14px 28px;
  border-radius: 8px;
  font-size: 1rem;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  transition: background 0.3s, transform 0.2s;

  &:hover {
    transform: ${(props) => (props.disabled ? 'none' : 'translateY(-2px)')};
    background: ${(props) => (props.disabled ? '#334155' : '#0284c7')};
  }

  @media (max-width: 1500px) {
    font-size: 0.9rem;
    padding: 12px 24px;
  }

  @media (max-width: 768px) {
    font-size: 0.875rem;
  }
`;

const ProgressBarContainer = styled.div`
  margin-top: 20px;
  height: 12px;
  background-color: #1e293b;
  border-radius: 6px;
  overflow: hidden;
`;

const ProgressBar = styled.div<{ progress: number }>`
  height: 100%;
  background: linear-gradient(90deg, #38bdf8, #0284c7);
  width: ${(props) => `${props.progress}%`};
  transition: width 0.3s;
`;

const ImageSectionTitle = styled.h3`
  font-size: 1.5rem;
  font-weight: 700;
  color: #38bdf8;

  @media (max-width: 1500px) {
    font-size: 1.4rem;
    margin-bottom: 12px;
  }

  @media (max-width: 768px) {
    font-size: 1.25rem;
  }
`;


const NewModelTrainer: React.FC<{ user: any; onModelAdded: (model: any) => void }> = ({ user, onModelAdded }) => {
  const [images, setImages] = useState<File[]>([]);
  const [triggerWord, setTriggerWord] = useState('');
  const [modelName, setModelName] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [progress, setProgress] = useState<number>(0);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      setImages((prev) => [...prev, ...Array.from(files)]);
    }
  };

  const handleRemoveImage = (index: number, event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation(); // Prevent triggering the file input
    setImages((prev) => prev.filter((_, i) => i !== index));
  };

  const handleSubmit = async () => {
    console.log('Submit button clicked');

    setIsSubmitting(true);
    try {
      // Create Firestore document with a placeholder ID
      const userCollection = collection(db, 'image_models', user.email, 'models');
      const docRef = await addDoc(userCollection, {}); // Add an empty document to get the docRef.id
      const modelId = docRef.id;
      // Build the model data including the modelId
      const modelData = {
        modelId, // Include the document ID as the modelId field
        modelName,
        triggerWord: triggerWord || 'John', // Default to "John" if not provided
        userEmail: user.email,
        timestamp: new Date(),
        storagePath: `/model_training_photos/${modelId}`,
        trainingStatus: 'inProgress',
      };
      // Update the document with the model data
      await updateDoc(docRef, modelData);
      // Upload images to Firebase Storage
      const uploadPromises = images.map((image, index) => {
        const storageRef = ref(storage, `model_training_photos/${modelId}/${index}_${image.name}`);
        return uploadBytes(storageRef, image);
      });
      // Wait for all uploads to complete
      await Promise.all(uploadPromises);
      // Prepare payload for publish_order Cloud Function
      const orderData = {
        userEmail: user.email,
        modelId,
        triggerWord: triggerWord || 'John', // Default to "John"
        modelTrainingOnly: true, // Indicate training-only mode
      };
      // Call the publish_order Cloud Function
      const idToken = await user.getIdToken();
      const response = await fetch(
        'https://us-central1-photoop-28a4c.cloudfunctions.net/publish_order',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${idToken}`,
          },
          body: JSON.stringify(orderData),
        }
      );
      if (!response.ok) {
        const errorText = await response.text();
        console.error('Error from publish_order:', errorText);
        throw new Error('Failed to publish order.');
      }
      // Notify parent component about the new model
      onModelAdded(modelData);
      alert('Model submitted successfully, images uploaded, and order published!');
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <TrainerContainer>
      {/* <IconSection>
        <div>
          <FiUser />
          <span>Create a model of yourself</span>
        </div>
        <div>
          <FiCamera />
          <span>Create a model of your pet</span>
        </div>
        <div>
          <FiHeart />
          <span>Create a model of a loved one</span>
        </div>
      </IconSection> */}

      <Title>Build a custom AI model</Title>

      <InputField
        value={modelName}
        onChange={(e) => setModelName(e.target.value)}
        placeholder="Enter model name"
      />

      <InputField
        value={triggerWord}
        onChange={(e) => setTriggerWord(e.target.value)}
        placeholder="Enter trigger word"
      />
      <DescriptionWithIcon>
        <FiInfo />
        <span>
          The trigger word is a unique keyword that identifies the subject of your model, like your name or your pet's name. Use it in prompts to generate images related to the subject.
        </span>
      </DescriptionWithIcon>

      <ImageSectionTitle>Upload Images</ImageSectionTitle>
      <Grid>
        {images.map((image, index) => (
          <PreviewImage key={index}>
            <img src={URL.createObjectURL(image)} alt="preview" />
            <button onClick={(event) => handleRemoveImage(index, event)}>×</button>
          </PreviewImage>
        ))}
        <PlaceholderBox>
          <input type="file" onChange={handleFileChange} multiple accept="image/*" />
          Upload
        </PlaceholderBox>
      </Grid>

      <SubmitButton
        disabled={isSubmitting || !modelName.trim() || !triggerWord.trim() || images.length === 0}
        onClick={handleSubmit}
      >
        {isSubmitting ? 'Submitting...' : 'Submit for Training'}
      </SubmitButton>

      {isSubmitting && (
        <ProgressBarContainer>
          <ProgressBar progress={progress} />
        </ProgressBarContainer>
      )}
    </TrainerContainer>
  );
};

export default NewModelTrainer;
