import React from 'react';
import styled from 'styled-components';
import { CheckIcon } from 'lucide-react';
import { FaAngleDoubleRight } from 'react-icons/fa';

const StepperWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StepsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
`;

const Step = styled.div<{ isActive: boolean; isCompleted: boolean }>`
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 12px 20px;
  border-radius: 16px;
  background: ${({ isActive }) => (isActive ? '#dbeafe' : '#f8fafc')}; /* Slightly darker blue for active steps */
  box-shadow: ${({ isActive }) =>
    isActive
      ? '0 4px 12px rgba(37, 99, 235, 0.3)' /* Slightly darker shadow */
      : '0 2px 8px rgba(0, 0, 0, 0.05)'};
  transition: all 0.3s ease;

  &:hover {
    box-shadow: 0 4px 16px rgba(37, 99, 235, 0.2); /* Consistent hover shadow */
  }

  opacity: ${({ isActive, isCompleted }) => (isActive || isCompleted ? 1 : 0.75)};
`;

const StepCircle = styled.div<{ isActive: boolean; isCompleted: boolean }>`
  width: 28px;
  height: 28px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ isCompleted }) => (isCompleted ? 'white' : '#1d4ed8')}; /* Slightly darker text blue */
  background: ${({ isCompleted }) => (isCompleted ? '#1d4ed8' : '#f1f5f9')}; /* Darker blue for completed steps */
  border: 2px solid ${({ isActive, isCompleted }) =>
    isCompleted ? '#1d4ed8' : isActive ? '#1d4ed8' : '#d1d5db'}; /* Adjusted border for active/completed */
  box-shadow: ${({ isActive }) =>
    isActive ? '0 2px 8px rgba(37, 99, 235, 0.3)' : 'none'}; /* Slightly darker shadow for active */
  font-weight: 600;
  transition: all 0.3s ease;
`;

const StepContent = styled.div`
  display: flex;
  flex-direction: column;
`;

const StepTitle = styled.div<{ isActive: boolean; isCompleted: boolean }>`
  font-size: 16px;
  font-weight: bold;
  color: ${({ isActive, isCompleted }) =>
    isActive || isCompleted ? '#1e3a8a' : '#374151'}; /* Adjusted color for better contrast */
  transition: color 0.3s ease;
`;

const ChevronIcon = styled(FaAngleDoubleRight) <{ isCompleted: boolean }>`
  color: ${({ isCompleted }) => (isCompleted ? '#1d4ed8' : '#d1d5db')}; /* Slightly darker blue for completed chevrons */
  font-size: 20px;
  transition: color 0.3s ease;
`;

interface Step {
  number: number;
  title: string;
  description: string;
}

interface StepperProps {
  currentStep: number;
  steps?: Step[];
  onStepClick?: (stepNumber: number) => void;
}

const Stepper: React.FC<StepperProps> = ({
  currentStep = 1,
  steps = [
    { number: 1, title: 'Select Styles', description: 'Lorem Ipsum is simply' },
    { number: 2, title: 'AI Personalization', description: 'Lorem Ipsum is simply' },
    { number: 3, title: 'Complete Order', description: 'Lorem Ipsum is simply' },
  ],
  onStepClick,
}) => {
  return (
    <StepperWrapper>
      <StepsContainer>
        {steps.map((step, index) => (
          <React.Fragment key={step.number}>
            <Step
              isActive={currentStep === step.number}
              isCompleted={currentStep > step.number}
              onClick={() => onStepClick?.(step.number)}
            >
              <StepCircle
                isActive={currentStep === step.number}
                isCompleted={currentStep > step.number}
              >
                {currentStep > step.number ? <CheckIcon size={16} /> : step.number}
              </StepCircle>
              <StepContent>
                <StepTitle
                  isActive={currentStep === step.number}
                  isCompleted={currentStep > step.number}
                >
                  {step.title}
                </StepTitle>
              </StepContent>
            </Step>
            {index < steps.length - 1 && (
              <ChevronIcon isCompleted={currentStep >= step.number} />
            )}
          </React.Fragment>
        ))}
      </StepsContainer>
    </StepperWrapper>
  );
};

export default Stepper;
