import React from 'react';
import { FaStar } from 'react-icons/fa';
import styled, { css, keyframes } from 'styled-components';
import { auth } from './firebaseConfig';
import { useAuthState } from 'react-firebase-hooks/auth';

interface OrderReviewProps {
    rating: number;
    setRating: (rating: number) => void;
    feedback: string;
    setFeedback: (feedback: string) => void;
    onSubmit: () => void;
    isSubmitted: boolean;
}

// Animations
const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const scaleIn = keyframes`
  from {
    transform: scale(0.95);
  }
  to {
    transform: scale(1);
  }
`;

// Styled Components
const ReviewContainer = styled.div`
  background: white;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.04);
  border: 1px solid #f0f0f0;
  padding: 24px;
  margin: 24px 0 16px;
  animation: ${fadeIn} 0.3s ease-out;
`;

const Title = styled.h3`
  font-size: 18px;
  font-weight: 600;
  color: #1a1a1a;
  margin-bottom: 20px;
`;

const Section = styled.div`
  margin-bottom: 24px;
`;

const Label = styled.label`
  display: block;
  font-size: 14px;
  font-weight: 500;
  color: #4a4a4a;
  margin-bottom: 8px;
`;

const StarContainer = styled.div`
  display: flex;
  gap: 8px;
  margin-bottom: 12px;
`;

interface StarButtonProps {
    $isFilled: boolean;
}

const StarButton = styled.button<StarButtonProps>`
  background: none;
  border: none;
  padding: 2px;
  cursor: pointer;
  transition: transform 0.2s ease;
  color: ${props => props.$isFilled ? '#FFB800' : '#E0E0E0'};

  &:hover {
    transform: scale(1.1);
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px rgba(255, 184, 0, 0.2);
    border-radius: 4px;
  }

  svg {
    width: 32px;
    height: 32px;
  }
`;

const RatingFeedback = styled.p`
  font-size: 14px;
  color: #666;
  margin-top: 8px;
  animation: ${fadeIn} 0.2s ease-out;
`;

const TextArea = styled.textarea`
  width: 100%;
  padding: 12px;
  border: 1px solid #E0E0E0;
  border-radius: 8px;
  font-size: 14px;
  line-height: 1.5;
  min-height: 120px;
  transition: all 0.2s ease;
  resize: vertical;

  &:focus {
    outline: none;
    border-color: #2563EB;
    box-shadow: 0 0 0 3px rgba(37, 99, 235, 0.1);
  }

  &::placeholder {
    color: #A0A0A0;
  }
`;

interface SubmitButtonProps {
    $disabled: boolean;
}

const SubmitButton = styled.button<SubmitButtonProps>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 12px 24px;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 500;
  transition: all 0.2s ease;
  border: none;
  
  ${props => props.$disabled ? css`
    background-color: #E0E0E0;
    color: #A0A0A0;
    cursor: not-allowed;
  ` : css`
    background-color: #2563EB;
    color: white;
    cursor: pointer;
    
    &:hover {
      background-color: #1D4ED8;
    }
    
    &:focus {
      outline: none;
      box-shadow: 0 0 0 3px rgba(37, 99, 235, 0.3);
    }

    &:active {
      transform: scale(0.98);
    }
  `}
`;

const SuccessContainer = styled.div`
  background-color: #ECFDF5;
  border-radius: 12px;
  padding: 24px;
  margin: 24px 0 16px;
  animation: ${scaleIn} 0.3s ease-out;
`;

const SuccessContent = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const IconContainer = styled.div`
  flex-shrink: 0;
  width: 40px;
  height: 40px;
  background-color: #34D399;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
`;

const SuccessMessage = styled.div`
  flex: 1;
`;

const SuccessTitle = styled.h3`
  font-size: 16px;
  font-weight: 600;
  color: #064E3B;
  margin-bottom: 4px;
`;

const SuccessText = styled.p`
  font-size: 14px;
  color: #065F46;
`;

const CheckIcon = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
        <path d="M20 6L9 17L4 12" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
);

const OrderReview: React.FC<OrderReviewProps> = ({
    rating,
    setRating,
    feedback,
    setFeedback,
    onSubmit,
    isSubmitted
}) => {
    const [user] = useAuthState(auth);
    const [isHovered, setIsHovered] = React.useState(-1);

    if (isSubmitted) {
        return (
            <SuccessContainer>
                <SuccessContent>
                    <IconContainer>
                        <CheckIcon />
                    </IconContainer>
                    <SuccessMessage>
                        <SuccessTitle>Thank you for your review!</SuccessTitle>
                        <SuccessText>Your feedback helps us improve our service for everyone.</SuccessText>
                    </SuccessMessage>
                </SuccessContent>
            </SuccessContainer>
        );
    }

    const getRatingFeedback = (rating: number) => {
        switch (rating) {
            case 5: return "Excellent! We're thrilled you loved it!";
            case 4: return "Great! Thank you for your positive feedback!";
            case 3: return "Thank you! Your feedback helps us improve.";
            case 2: return "We're sorry we didn't meet your expectations.";
            case 1: return "We apologize for your experience.";
            default: return "";
        }
    };

    return (
        <ReviewContainer>
            <Title>Rate Your Experience</Title>

            <Section>
                <StarContainer>
                    {[1, 2, 3, 4, 5].map((star) => (
                        <StarButton
                            key={star}
                            type="button"
                            $isFilled={star <= (isHovered !== -1 ? isHovered : rating)}
                            onMouseEnter={() => setIsHovered(star)}
                            onMouseLeave={() => setIsHovered(-1)}
                            onClick={() => setRating(star)}
                            aria-label={`Rate ${star} stars`}
                        >
                            <FaStar />
                        </StarButton>
                    ))}
                </StarContainer>
                {rating > 0 && (
                    <RatingFeedback>{getRatingFeedback(rating)}</RatingFeedback>
                )}
            </Section>

            <Section>
                <Label htmlFor="feedback">Share your thoughts (optional)</Label>
                <TextArea
                    id="feedback"
                    value={feedback}
                    onChange={(e) => setFeedback(e.target.value)}
                    placeholder="Tell us what you liked or how we can improve..."
                />
            </Section>

            <SubmitButton
                type="button"
                onClick={onSubmit}
                disabled={rating === 0}
                $disabled={rating === 0}
            >
                Submit Review
            </SubmitButton>
        </ReviewContainer>
    );
};

export default OrderReview;