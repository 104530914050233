// BeforeAndAfterCarousel.tsx

import React, { useState, useEffect } from 'react';
import styled, { keyframes, css } from 'styled-components';
import { useNavigate } from 'react-router-dom'; // Import useNavigate for navigation
import { FaAngleDoubleRight } from 'react-icons/fa';

import owenAfter from './assets/beforeAfter/owenAfter.png';
import owenBefore from './assets/beforeAfter/owenBefore.png';

import omarAfter from './assets/linkedin/omar_creative_professional.png';
import omarBefore from './assets/beforeAfter/omarBefore.png';

import justinAfter from './assets/beforeAfter/justinAfter.png';
import justinBefore from './assets/beforeAfter/justinBefore.png';

import tinaAfter from './assets/beforeAfter/tinaAfter.png';
import tinaBefore from './assets/beforeAfter/tinaBefore.png';

const beforeAfterPairs = [
  {
    before: tinaBefore,
    after: tinaAfter,
    description: "What do I look like with a buzzcut?",
  },
  {
    before: owenBefore,
    after: owenAfter,
    description: "Give me a classic polaroid look",
  },
  {
    before: omarBefore,
    after: omarAfter,
    description: "I need a new photo for Instagram",
  },
  {
    before: justinBefore,
    after: justinAfter,
    description: "Can I get a new LinkedIn pic?",
  },
  // Add more pairs as needed
];

// Keyframe animations for fade-in and fade-out
const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const fadeOut = keyframes`
  from { opacity: 1; }
  to { opacity: 0; }
`;

// Styled components
const CarouselContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  padding: 16px;
  flex-direction: column;
  cursor: pointer; /* Indicates that the carousel is clickable */
`;

const ImageWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center; /* Ensures vertical centering */
  width: 100%;
  height: 350px;

  @media (max-width: 1200px) {
    justify-content: center;
  }
`;

const StyledIcon = styled(FaAngleDoubleRight)`
  font-size: 36px;
  color: #1d4ed8;
  margin: 0 16px;

  @media (max-width: 480px) {
    font-size: 28px;
    margin: 0px;
  }
`;

const DescriptionCaptionWrapper = styled.div`
  width: 100%;
`;

const FadeImageWrapper = styled.div<{ fadeType: 'fade-in' | 'fade-out' }>`
  display: flex;
  animation: ${({ fadeType }) =>
    fadeType === 'fade-in'
      ? css`
          ${fadeIn} 1s ease-in-out forwards
        `
      : css`
          ${fadeOut} 1s ease-in-out forwards
        `};
`;

const ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Image = styled.img`
  width: 260px;
  height: 325px;
  object-fit: cover;
  border-radius: 16px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 8px;

  @media (max-width: 480px) {
    width: 182px;
    height: 227.5px;
  }
`;

const CarouselContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 600px;
`;

const Description = styled.div`
  font-size: 1.2rem;
  font-style: italic;
  font-weight: 500;
  color: #ffffff;
  text-align: center;
  width: 100%;
  max-width: 600px;
  padding: 12px 16px;
  border-radius: 12px;
  margin-bottom: 24px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  background: linear-gradient(135deg, #3b82f6 0%, #2563eb 100%);
  position: relative;

  /* Wrapping text in quotes */
  &::before,
  &::after {
    content: '"';
    font-size: 24px;
    font-weight: bold;
    color: #ffffff;
    vertical-align: middle;
  }

  &::before {
    margin-right: 8px;
  }

  &::after {
    margin-left: 8px;
  }

  @media (max-width: 480px) {
    margin-top: 0px;
    margin-bottom: 12px;
  }
`;

// Duration constants
const FADE_DURATION = 1000; // 1 second
const DISPLAY_DURATION = 7000; // 7 seconds per image pair

const BeforeAndAfterCarousel: React.FC = () => {
  const [currentPairIndex, setCurrentPairIndex] = useState(0);
  const [fadeType, setFadeType] = useState<'fade-in' | 'fade-out'>('fade-in');

  const navigate = useNavigate(); // Get the navigate function from react-router-dom

  useEffect(() => {
    let fadeOutTimeout: NodeJS.Timeout;
    let fadeInTimeout: NodeJS.Timeout;

    const startFadeOut = () => {
      setFadeType('fade-out');
      // After fade-out completes, update the image pair and start fade-in
      fadeOutTimeout = setTimeout(() => {
        setCurrentPairIndex(
          (prevIndex) => (prevIndex + 1) % beforeAfterPairs.length
        );
        setFadeType('fade-in');
      }, FADE_DURATION);
    };

    // Set interval to start fade-out after DISPLAY_DURATION
    const interval = setInterval(() => {
      startFadeOut();
    }, DISPLAY_DURATION);

    // Initially, set a timeout to start the first fade-out
    fadeInTimeout = setTimeout(() => {
      startFadeOut();
    }, DISPLAY_DURATION);

    // Cleanup on unmount
    return () => {
      clearInterval(interval);
      clearTimeout(fadeOutTimeout);
      clearTimeout(fadeInTimeout);
    };
  }, []);

  const { before, after, description } = beforeAfterPairs[currentPairIndex];

  // Handle click on the carousel
  const handleCarouselClick = () => {
    navigate('/select-template'); // Navigate to the template selection page
  };

  return (
    <CarouselContainer onClick={handleCarouselClick}>
      <CarouselContentWrapper>
        <DescriptionCaptionWrapper>
          <FadeImageWrapper
            key={`description-${currentPairIndex}`}
            fadeType={fadeType}
          >
            <Description>{description}</Description>
          </FadeImageWrapper>
        </DescriptionCaptionWrapper>
        <ImageWrapper>
          <FadeImageWrapper
            key={`before-${currentPairIndex}`}
            fadeType={fadeType}
          >
            <ImageContainer>
              <Image src={before} alt="Before" />
            </ImageContainer>
          </FadeImageWrapper>
          <FadeImageWrapper
            key={`arrow-${currentPairIndex}`}
            fadeType={fadeType}
          >
            <StyledIcon />
          </FadeImageWrapper>
          <FadeImageWrapper
            key={`after-${currentPairIndex}`}
            fadeType={fadeType}
          >
            <ImageContainer>
              <Image src={after} alt="After" />
            </ImageContainer>
          </FadeImageWrapper>
        </ImageWrapper>
      </CarouselContentWrapper>
    </CarouselContainer>
  );
};

export default BeforeAndAfterCarousel;
