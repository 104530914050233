import React, { useEffect } from 'react';
import styled from 'styled-components';

// Styled Components
const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-around;
  gap: 48px;
  padding: 32px;
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
`;

const InfoSection = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const PromptSection = styled.div`
  background-color: #ffffff; // Tailwind White
  padding: 24px;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  border: 1px solid #e5e7eb; // Tailwind Gray-200
`;

const Metadata = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  font-family: 'Courier New', Courier, monospace;
  font-size: 0.9rem;
  color: #6b7280; // Tailwind Gray-500
`;

const MetadataItem = styled.div`
  display: flex;
  justify-content: start;
  gap: 8px;
  align-items: baseline;
`;

const PromptTitle = styled.h3`
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 16px;
  color: #2563eb; // Tailwind Blue-600
`;

const PromptTextarea = styled.textarea`
  width: 100%;
  height: 150px;
  padding: 16px;
  border: 1px solid #d1d5db; // Tailwind Gray-300
  border-radius: 8px;
  background-color: #ffffff; // Tailwind White
  color: #111827; // Tailwind Gray-900
  font-family: 'Courier New', Courier, monospace;
  font-size: 1rem;
  resize: none;

  &:focus {
    outline: none;
    border-color: #2563eb; // Tailwind Blue-600
    box-shadow: 0 0 0 3px rgba(37, 99, 235, 0.3);
  }
`;

const GenerateButton = styled.button`
  background-color: #10b981; // Tailwind Emerald-500
  color: #ffffff;
  border: none;
  padding: 16px 32px;
  font-size: 1rem;
  border-radius: 8px;
  cursor: pointer;
  font-weight: 600;
  transition: background 0.3s, transform 0.2s;
  margin-top: 16px;

  &:hover {
    background-color: #059669; // Tailwind Emerald-600
    box-shadow: 0 6px 12px rgba(16, 185, 129, 0.2); // Emerald shadow
  }

  &:disabled {
    background-color: #9ca3af; // Tailwind Gray-400
    cursor: not-allowed;
  }
`;

const ImageSection = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
`;

const ImagePreview = styled.div`
  width: 100%;
  max-width: 500px;
  height: 500px;
  background-color: #f3f4f6; // Tailwind Gray-100
  border-radius: 12px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  border: 1px solid #e5e7eb; // Tailwind Gray-200
`;

const GeneratedImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const PlaceholderText = styled.p`
  color: #6b7280; // Tailwind Gray-500
  font-size: 1rem;
`;

// Types
type Model = {
  id: string;
  modelName: string;
  storagePath: string;
  timestamp: any; // Could be Firebase Timestamp or Date
  userEmail: string;
  trainingStatus?: string; // Optional, as it might not always exist
  trainingProgress?: number; // Optional, for progress tracking
  triggerWord: string;
};

interface ModelQueryUIProps {
  selectedModel: Model;
  prompt: string;
  setPrompt: (value: string) => void;
  handleGenerateImage: () => void;
  isLoadingImages: boolean;
  generatedImages: string[]; // Array of generated image URLs
}

// Main Component
const ModelQueryUI: React.FC<ModelQueryUIProps> = ({
  selectedModel,
  prompt,
  setPrompt,
  handleGenerateImage,
  isLoadingImages,
  generatedImages,
}) => {
  // Clear prompt when the selected model changes
  useEffect(() => {
    setPrompt('');
  }, [selectedModel, setPrompt]);

  return (
    <Container>
      <InfoSection>
        <PromptSection>
          <PromptTitle>Model: {selectedModel.modelName}</PromptTitle>
          <Metadata>
            <MetadataItem>
              <span>Trigger word:</span>
              <span>{selectedModel.triggerWord}</span>
            </MetadataItem>
            <MetadataItem>
              <span>Status:</span>
              <span>{selectedModel.trainingStatus === 'complete' ? 'Ready to use' : 'In Progress'}</span>
            </MetadataItem>
          </Metadata>
          <PromptTextarea
            value={prompt}
            onChange={(e) => setPrompt(e.target.value)}
            placeholder="Enter your prompt here..."
          />
          <GenerateButton
            onClick={handleGenerateImage}
            disabled={isLoadingImages}
          >
            {isLoadingImages ? 'Generating...' : 'Generate Images'}
          </GenerateButton>
        </PromptSection>
      </InfoSection>
      <ImageSection>
        <ImagePreview>
          {generatedImages.length > 0 ? (
            <GeneratedImage src={generatedImages[0]} alt="Generated" />
          ) : (
            <PlaceholderText>No image generated yet.</PlaceholderText>
          )}
        </ImagePreview>
      </ImageSection>
    </Container>
  );
};

export default ModelQueryUI;
