import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

// Styled Components
const ProgressContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 32px;
  width: 100%;
  height: 100vh;
  color: #1f2937; // Tailwind Gray-800
`;

const ProgressHeader = styled.h3`
  font-size: 2.5rem;
  font-weight: 800;
  color: #2563eb; // Tailwind Blue-600
  margin-bottom: 32px;
`;

const ModelDetails = styled.div`
  margin-bottom: 32px;
`;

const ModelInfo = styled.p`
  font-size: 1.25rem;
  margin: 8px 0;
  color: #374151; // Tailwind Gray-700
`;

const ProgressBarContainer = styled.div`
  width: 80%;
  height: 24px;
  background-color: #e5e7eb; // Tailwind Gray-200
  border-radius: 12px;
  overflow: hidden;
  margin: 24px 0;
  position: relative;
`;

const ProgressBar = styled.div<{ progress: number }>`
  height: 100%;
  background: linear-gradient(90deg, #3b82f6, #2563eb);
  width: ${(props) => `${props.progress}%`};
  transition: width 2s ease-in-out;
`;

const StatusText = styled.p`
  font-size: 1.5rem;
  font-weight: 600;
  color: #2563eb; // Tailwind Blue-600
  margin-top: 16px;
`;

const EstimatedTime = styled.p`
  font-size: 1.25rem;
  color: #6b7280; // Tailwind Gray-500
  margin-top: 8px;
`;

// Helper Functions
const calculateProgress = (startTime: Date) => {
  const now = new Date();
  const elapsedTime = (now.getTime() - startTime.getTime()) / (1000 * 60 * 60); // Hours
  const progress = Math.min((elapsedTime / 3) * 100, 100); // Cap at 100%
  const remainingTime = Math.max(3 - elapsedTime, 0); // Remaining time in hours

  return { progress, remainingTime };
};

// Types
type Model = {
  id: string;
  modelName: string;
  trainingStatus: string; // e.g., "inProgress", "complete"
  timestamp: any; // Could be Firebase Timestamp or Date
  userEmail: string;
};

interface ModelProgressUIProps {
  selectedModel: Model;
}

// Main Component
const ModelProgressUI: React.FC<ModelProgressUIProps> = ({ selectedModel }) => {
  const [progress, setProgress] = useState(0);
  const [remainingTime, setRemainingTime] = useState(3);

  const startTime = selectedModel.timestamp instanceof Date
    ? selectedModel.timestamp
    : selectedModel.timestamp.toDate(); // Handle Firebase Timestamp

  useEffect(() => {
    const interval = setInterval(() => {
      const { progress: newProgress, remainingTime: newRemainingTime } = calculateProgress(startTime);
      setProgress(newProgress);
      setRemainingTime(newRemainingTime);
    }, 3000); // Update every 3 seconds

    return () => clearInterval(interval);
  }, [startTime]);

  return (
    <ProgressContainer>
      <ProgressHeader>Model Training Progress</ProgressHeader>
      <ModelDetails>
        <ModelInfo>
          <strong>Model Name:</strong> {selectedModel.modelName}
        </ModelInfo>
        <ModelInfo>
          <strong>Status:</strong> {selectedModel.trainingStatus}
        </ModelInfo>
      </ModelDetails>

      <ProgressBarContainer>
        <ProgressBar progress={progress} />
      </ProgressBarContainer>

      <StatusText>{progress.toFixed(2)}% Complete</StatusText>
      <EstimatedTime>Estimated time remaining: ~{remainingTime.toFixed(2)} hours</EstimatedTime>
    </ProgressContainer>
  );
};

export default ModelProgressUI;
