// src/components/TemplateSelectionPage.tsx

import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import styled, { createGlobalStyle, keyframes } from 'styled-components';
import { ToastContainer, toast } from 'react-toastify';
import { FaChevronLeft, FaChevronRight, FaGift, FaTag } from 'react-icons/fa';
import { useAuthState } from 'react-firebase-hooks/auth';
import { getDoc, doc } from 'firebase/firestore';
import { logEvent } from 'firebase/analytics';
import { Link } from 'react-router-dom';

import Navbar from './Navbar';
import { templateCategories, TemplateService, Template } from './templates';
import { auth, analytics, db } from './firebaseConfig';

import 'react-toastify/dist/ReactToastify.css';

// Instantiate the TemplateService
const templateService = new TemplateService(templateCategories);

// =========================
// Utility Functions for Color Manipulation
// =========================

// Color Palette
const colors = {
  primary: '#2563EB',       // Blue-600
  secondary: '#3B82F6',     // Blue-500
  background: '#F9FAFB',    // Gray-50
  surface: '#FFFFFF',
  error: '#DC2626',         // Red-600
  onPrimary: '#FFFFFF',
  onBackground: '#111827',  // Gray-900
};

const hexToRgb = (hex: string): string => {
  hex = hex.replace('#', '');
  const bigint = parseInt(hex, 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;
  return `${r}, ${g}, ${b}`;
};

// =========================
// Global Styles
// =========================

const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&display=swap');
  
  body, html {
    margin: 0;
    padding: 0;
    font-family: 'Inter', sans-serif;
    background-color: #ffffff;
    color: #333333;
    box-sizing: border-box;
    height: 100%;
    overflow: hidden; /* Prevent body from scrolling */
  }

  *, *::before, *::after {
    box-sizing: inherit;
  }
`;

// =========================
// Keyframes for animations
// =========================

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(16px);
  } 
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

// =========================
// Styled Components
// =========================

// Fullscreen container
const FullscreenContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
`;

// Content container
const ContentContainer = styled.div`
  flex: 1;
  overflow-y: auto; /* Allow content to scroll within this container */
  padding: 48px 64px;
  padding-bottom: 120px; /* Adjusted space for BottomNav */
  animation: ${fadeIn} 0.8s ease-in;

  @media (min-width: 1500px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  @media (max-width: 1024px) {
    padding: 32px 48px;
  }

  @media (max-width: 768px) {
    padding: 24px 32px;
  }

  @media (max-width: 480px) {
    padding: 16px 16px;
  }
`;

const PreviewButton = styled(Link)`
  background-color: transparent;
  color: ${colors.primary};
  border: 1px solid ${colors.primary};
  padding: 8px 16px;
  font-size: 0.9rem;
  font-weight: 600;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
  margin-right: 8px;

  &:hover {
    background-color: ${colors.primary};
    color: ${colors.onPrimary};
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px ${colors.primary}33;
  }

  @media (max-width: 768px) {
    padding: 6px 12px;
    font-size: 0.8rem;
  }
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px 32px;
  background: linear-gradient(135deg, #1a365d 0%, #2563EB 100%);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 16px;
  width: 100%;
  max-width: 1450px;
  margin-bottom: 24px;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0.1) 0%, transparent 100%);
    pointer-events: none;
  }

  @media (min-width: 1920px) {
    max-width: 1850px;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    text-align: center;
    gap: 16px;
    padding: 20px 24px;
  }
`;

const Title = styled.h1`
  font-family: 'Inter', sans-serif;
  font-size: 1.6rem;
  font-weight: 700;
  color: white;
  margin: 0;

  @media (max-width: 1024px) {
    font-size: 1.4rem;
  }

  @media (max-width: 768px) {
    font-size: 1.2rem;
  }
`;

const PortraitsBadge = styled.span<{ backgroundColor: string }>`
  background-color: ${(props) => props.backgroundColor};
  color: #ffffff;
  padding: 2px 8px;
  font-size: 0.75rem;
  font-weight: 600;
  border-radius: 4px;
  width: 100%;
`;

const Pill = styled.span<{ backgroundColor: string; textColor: string }>`
  display: inline-block;
  padding: 8px 16px;
  font-size: 0.9rem;
  font-weight: 600;
  color: ${(props) => props.textColor};
  background-color: ${(props) => props.backgroundColor};
  border-radius: 9999px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  white-space: nowrap;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 0.8rem;
  }
`;

const CategoryHeader = styled.div`
  width: 100%; // Add this line
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 16px;
  margin-bottom: 8px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
  }
`;



// Category Title
const CategoryTitle = styled.h2`
  font-size: 1.4rem;
  font-weight: bold;
  margin-left: 8px;
  margin-bottom: 8px;
  color: ${colors.onBackground};

  @media (max-width: 1024px) {
    font-size: 1.4rem;
  }

  @media (max-width: 768px) {
    font-size: 1.3rem;
  }

  @media (max-width: 480px) {
    font-size: 1.2rem;
  }
`;

// Badge Component
const Badge = styled.span<{ backgroundColor: string }>`
  position: absolute;
  top: 12px;
  left: 12px;
  background-color: ${(props) => props.backgroundColor};
  color: #ffffff;
  padding: 4px 8px;
  font-size: 0.75rem;
  font-weight: 600;
  border-radius: 4px;
  display: flex;
  align-items: center;
  z-index: 3;
`;

const OverlayContainer = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 12px;
  background: linear-gradient(
    to top,
    rgba(0, 0, 0, 0.7) 0%,
    rgba(0, 0, 0, 0.0) 100%
  );
  color: #fff;
`;

// Selection Overlay
const SelectionOverlay = styled.div<{ selected: boolean }>`
  display: ${(props) => (props.selected ? 'flex' : 'none')};
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 116, 162, 0.7);
  color: #fff;
  font-size: 1.2rem;
  font-weight: 700;
  align-items: center;
  justify-content: center;
  text-align: center;
  z-index: 2;
`;

// Bottom Navigation Bar
const BottomNav = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 8px 24px;
  background-color: #ffffff;
  border-top: 1px solid #E5E7EB;
  z-index: 1000;

  @media (max-width: 480px) {
    padding: 8px 16px;
  }
`;

// Navigation Button
const NavButton = styled.button<{ primary?: boolean }>`
  background-color: ${(props) => (props.primary ? colors.primary : 'transparent')};
  color: ${(props) => (props.primary ? colors.onPrimary : colors.primary)};
  padding: 12px 24px;
  font-size: 1rem;
  font-weight: 600;
  border: ${(props) => (props.primary ? 'none' : `2px solid ${colors.primary}`)};
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;

  &:hover {
    background-color: ${(props) =>
    props.primary ? colors.secondary : `${colors.primary}1A`};
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px ${colors.primary}33;
  }

  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }

  @media (max-width: 480px) {
    padding: 10px 20px;
    font-size: 0.9rem;
  }
`;

// =========================
// Styled Components for TemplateCategorySection
// =========================

const OwenContainer = styled.div`
  width: 100%; // Ensure it takes up the full width
  max-width: 1450px;
  margin: 0 auto;

  @media (min-width: 1920px) {
    max-width: 1850px;
  }
`;

// Wrapper for the Templates Grid and Arrows
const TemplatesGridWrapper = styled.div`
  position: relative;
`;

// Modify TemplatesGrid to handle both horizontal and vertical layouts
const TemplatesGridStyled = styled.div<{ isMobile: boolean }>`
  display: flex;
  flex-direction: ${(props) => (props.isMobile ? 'column' : 'row')};
  overflow-x: ${(props) => (props.isMobile ? 'visible' : 'auto')};
  scroll-behavior: smooth;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  & > * {
    flex: 0 0 auto;
  }

  ${(props) =>
    props.isMobile &&
    `
    & > * {
      margin-bottom: 16px;
    }
  `}
`;

// Arrow Buttons
const Arrow = styled.div<{ visible: boolean }>`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 50%;
  padding: 8px;
  display: ${(props) => (props.visible ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;

  @media (max-width: 480px) {
    display: none;
  }

  &:hover {
    background-color: rgba(255, 255, 255, 0.9);
  }
`;

const LeftArrow = styled(Arrow)`
  left: 8px;
`;

const RightArrow = styled(Arrow)`
  right: 8px;
`;

const TemplateTitle = styled.h3`
  font-size: 1rem;
  font-weight: 600;
  margin: 0;
  margin-bottom: 4px;
`;

// Modify TemplateCard to adjust width and flex properties
const TemplateCardStyled = styled.div<{ selected: boolean; isMobile: boolean }>`
  position: relative;
  background-color: #ffffff;
  border: 2px solid ${(props) => (props.selected ? '#0074a2' : '#e0e0e0')};
  border-radius: 16px;
  overflow: hidden;
  cursor: pointer;
  transition: transform 0.3s ease, border-color 0.3s ease, box-shadow 0.3s ease;
  box-shadow: ${(props) =>
    props.selected
      ? '0 4px 16px rgba(0, 116, 162, 0.2)'
      : '0 2px 8px rgba(0, 0, 0, 0.05)'};
  min-width: ${(props) => (props.isMobile ? '100%' : '270px')};
  max-width: ${(props) => (props.isMobile ? '100%' : '270px')};
  flex: 0 0 auto;
  margin-right: ${(props) => (props.isMobile ? '0' : '24px')};
  margin-bottom: ${(props) => (props.isMobile ? '16px' : '0')};

  &:hover {
    border-color: #0074a2;
    box-shadow: 0 6px 20px rgba(${hexToRgb('#0074a2')}, 0.2);
  }

  @media (min-width: 1920px) {
    min-width: ${(props) => (props.isMobile ? '100%' : '350px')};
  }
`;

// =========================
// TemplateCategorySection Component
// =========================

interface TemplateCategorySectionProps {
  category: {
    name: string;
    bundlePrice: number;
    templates: Template[];
  };
  selectedTemplates: string[];
  handleTemplateSelect: (templateId: string) => void;
  getCategoryNameByTemplateId: (templateId: string) => string;
  handleToggleSelectAllInCategory: (categoryName: string) => void;
  hasDoneFreeOrder: boolean;
}

const TemplateCategorySection: React.FC<TemplateCategorySectionProps> = ({
  category,
  selectedTemplates,
  handleTemplateSelect,
  getCategoryNameByTemplateId,
  handleToggleSelectAllInCategory,
  hasDoneFreeOrder,
}) => {
  const gridRef = useRef<HTMLDivElement>(null);
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(false);
  const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth <= 480);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 480);
      updateArrows();
    };

    window.addEventListener('resize', handleResize);

    // Initial check
    updateArrows();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const updateArrows = () => {
    const grid = gridRef.current;
    if (grid && !isMobile) {
      const { scrollWidth, clientWidth } = grid;
      setShowRightArrow(scrollWidth > clientWidth);
      setShowLeftArrow(false);
    } else {
      setShowLeftArrow(false);
      setShowRightArrow(false);
    }
  };

  const handleScroll = () => {
    const grid = gridRef.current;
    if (grid && !isMobile) {
      const { scrollLeft, scrollWidth, clientWidth } = grid;
      setShowLeftArrow(scrollLeft > 0);
      setShowRightArrow(scrollLeft + clientWidth < scrollWidth);
    }
  };

  const scrollAmount = 300;

  const scrollLeft = () => {
    const grid = gridRef.current;
    if (grid) {
      grid.scrollBy({ left: -scrollAmount, behavior: 'smooth' });
    }
  };

  const scrollRight = () => {
    const grid = gridRef.current;
    if (grid) {
      grid.scrollBy({ left: scrollAmount, behavior: 'smooth' });
    }
  };

  return (
    <OwenContainer>
      <CategoryHeader>
        <CategoryTitle>{category.name}</CategoryTitle>
        {category.name === 'LinkedIn Professional' && (
          <PreviewButton to="/order-example">
            See Example Order
          </PreviewButton>
        )}
      </CategoryHeader>
      <TemplatesGridWrapper>
        {/* Left Arrow */}
        <LeftArrow onClick={scrollLeft} visible={showLeftArrow}>
          <FaChevronLeft />
        </LeftArrow>

        {/* Right Arrow */}
        <RightArrow onClick={scrollRight} visible={showRightArrow}>
          <FaChevronRight />
        </RightArrow>

        <TemplatesGridStyled ref={gridRef} onScroll={handleScroll} isMobile={isMobile}>
          {category.templates.map((template) => {
            const isSelected = selectedTemplates.includes(template.id);
            const isDiscounted = template.isDiscounted || false;
            const isFree = template.isPaid === false && !hasDoneFreeOrder;

            return (
              <TemplateCardStyled
                key={template.id}
                selected={isSelected}
                onClick={() => handleTemplateSelect(template.id)}
                tabIndex={0}
                aria-selected={isSelected}
                role="button"
                aria-label={`Select ${template.title} template`}
                onKeyPress={(e) => {
                  if (e.key === 'Enter' || e.key === ' ') {
                    handleTemplateSelect(template.id);
                  }
                }}
                isMobile={isMobile}
              >
                {/* Selection Overlay */}
                <SelectionOverlay selected={isSelected}>Selected</SelectionOverlay>

                {(isFree || isDiscounted) && (
                  <Badge backgroundColor={isFree ? '#28a745' : '#FF5722'}>
                    {isFree ? (
                      <>
                        <FaGift style={{ marginRight: '4px' }} />
                        Free for first time user
                      </>
                    ) : (
                      <>
                        <FaTag style={{ marginRight: '4px' }} />
                        Popular
                      </>
                    )}
                  </Badge>
                )}

                {/* Template Image */}
                <div
                  style={{
                    width: '100%',
                    height: '0',
                    paddingBottom: '100%',
                    backgroundImage: `url(${template.afterImage})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                  }}
                />

                {/* Overlay Container */}
                <OverlayContainer>
                  <TemplateTitle>{template.title}</TemplateTitle>

                  {/* Price Badge (only for paid templates) */}
                  {!isFree && (
                    <PortraitsBadge
                      style={{
                        display: 'inline-block', // Wrap content without stretching
                        width: 'auto', // Prevent taking up extra space
                      }}
                      backgroundColor="#3d464d"
                    >
                      ${isDiscounted
                        ? ((category.name === 'LinkedIn Professional' ? 7.99 : 2.99) * 0.75).toFixed(2)
                        : category.name === 'LinkedIn Professional'
                          ? '7.99'
                          : '2.99'}
                    </PortraitsBadge>
                  )}
                </OverlayContainer>
              </TemplateCardStyled>
            );
          })}
        </TemplatesGridStyled>
      </TemplatesGridWrapper>
    </OwenContainer>
  );
};

// =========================
// Interface for props
// =========================

interface TemplateSelectionPageProps {
  onSelectTemplates: (templates: string[]) => void;
}

// =========================
// Template Selection Page Component
// =========================

const TemplateSelectionPage: React.FC<TemplateSelectionPageProps> = ({
  onSelectTemplates,
}) => {
  const navigate = useNavigate();
  const [selectedTemplates, setSelectedTemplates] = useState<string[]>([]);
  const [user] = useAuthState(auth);
  const [hasDoneFreeOrder, setHasDoneFreeOrder] = useState<boolean>(false);

  // State for animated photo count
  const [animatedPhotos, setAnimatedPhotos] = useState<number>(0);
  const photosRef = useRef<number>(0);

  useEffect(() => {
    const checkIfUserHasDoneFreeOrder = async () => {
      if (user) {
        try {
          const freeOrderDocRef = doc(db, 'freeOrders', user.uid);
          const freeOrderDoc = await getDoc(freeOrderDocRef);
          if (freeOrderDoc.exists()) {
            setHasDoneFreeOrder(true);
          } else {
            setHasDoneFreeOrder(false);
          }
        } catch (error) {
          console.error('Error checking free order status:', error);
        }
      }
    };

    checkIfUserHasDoneFreeOrder();
  }, [user]);

  useEffect(() => {
    const calculateTargetPhotos = () => {
      return selectedTemplates.reduce((total, templateId) => {
        const template = templateService.findTemplateById(templateId);
        if (!template) return total;

        const isFreeForUser = template.isPaid === false && !hasDoneFreeOrder;
        return total + (isFreeForUser ? 50 : 50);
      }, 0);
    };

    const targetPhotos = calculateTargetPhotos();
    const duration = 500; // duration of animation in ms
    const frameRate = 60; // frames per second
    const totalFrames = (duration / 1000) * frameRate;
    const increment = (targetPhotos - photosRef.current) / totalFrames;
    let frame = 0;

    const animate = () => {
      frame++;
      const newCount = Math.round(photosRef.current + increment);
      setAnimatedPhotos(newCount);
      photosRef.current = newCount;
      if (frame < totalFrames) {
        requestAnimationFrame(animate);
      } else {
        setAnimatedPhotos(targetPhotos);
        photosRef.current = targetPhotos;
      }
    };

    animate();
  }, [selectedTemplates, hasDoneFreeOrder]);

  const handleTemplateSelect = (templateId: string) => {
    const isSelected = selectedTemplates.includes(templateId);

    if (isSelected) {
      setSelectedTemplates(selectedTemplates.filter((id) => id !== templateId));
    } else {
      setSelectedTemplates([...selectedTemplates, templateId]);
    }

    const template = templateService.findTemplateById(templateId);

    if (analytics && template) {
      logEvent(analytics, 'template_selected', {
        template_id: template.id,
        template_title: template.title,
        is_paid: template.isPaid ? 'yes' : 'no',
        is_discounted: template.isDiscounted ? 'yes' : 'no',
        category_name: getCategoryNameByTemplateId(template.id),
      });
    }
  };

  const getCategoryNameByTemplateId = (templateId: string): string => {
    for (const category of templateCategories) {
      if (category.templates.some((template) => template.id === templateId)) {
        return category.name;
      }
    }
    return 'Unknown';
  };

  // New function to handle select/deselect all templates in a category
  const handleToggleSelectAllInCategory = (categoryName: string) => {
    const category = templateCategories.find((cat) => cat.name === categoryName);
    if (!category) return;

    const categoryTemplateIds = category.templates.map((template) => template.id);

    const allSelected = categoryTemplateIds.every((id) =>
      selectedTemplates.includes(id)
    );

    if (allSelected) {
      // Deselect all templates in the category
      setSelectedTemplates(selectedTemplates.filter((id) => !categoryTemplateIds.includes(id)));
    } else {
      // Select all templates in the category
      setSelectedTemplates([...new Set([...selectedTemplates, ...categoryTemplateIds])]);
    }
  };

  const handleNext = () => {
    if (selectedTemplates.length > 0) {
      onSelectTemplates(selectedTemplates);

      // Calculate total price
      const totalPrice = calculateTotalPriceForTemplates(selectedTemplates);

      // Log event when 'Next' button is clicked
      if (analytics) {
        logEvent(analytics, 'step1_next_button_clicked', {
          selected_templates_count: selectedTemplates.length,
          total_price: totalPrice.toFixed(2),
        });
      }

      navigate('/upload-photos');
    } else {
      toast.warn('Please select at least one template to proceed.', {
        position: 'top-center',
        autoClose: 3000,
      });
    }
  };

  const calculateTotalPriceForTemplates = (templateIds: string[]) => {
    let totalPrice = 0;
    const countedTemplates = new Set<string>();

    templateCategories.forEach((category) => {
      const categoryTemplateIds = category.templates.map((template) => template.id);
      const selectedTemplatesInCategory = categoryTemplateIds.filter((id) =>
        templateIds.includes(id)
      );

      selectedTemplatesInCategory.forEach((id) => {
        if (!countedTemplates.has(id)) {
          const template = templateService.findTemplateById(id);
          if (!template) return;

          const isFreeForUser = template.isPaid === false && !hasDoneFreeOrder;
          let price = category.name === 'LinkedIn Professional' ? 7.99 : 2.99;

          if (isFreeForUser) {
            // Free template, no cost added
          } else if (template.isDiscounted) {
            totalPrice += price * 0.75;
          } else {
            totalPrice += price;
          }
          countedTemplates.add(id);
        }
      });
    });

    return totalPrice;
  };

  // Calculate total price
  const totalPrice = calculateTotalPriceForTemplates(selectedTemplates);

  return (
    <>
      <GlobalStyle />
      <ToastContainer />
      <FullscreenContainer>
        <Navbar
          navItems={[
            {
              label: 'Home',
              to: '/',
            },
          ]}
          showSignOutButton={!!user}
          currentStep={1}
        />

        <ContentContainer>
          <HeaderContainer>
            <Title>50 Headshots per pack</Title>
            <div style={{ display: 'flex', gap: '12px', alignItems: 'center' }}>
              <Pill backgroundColor="#e5e5e5" textColor="#333333">
                {animatedPhotos} Portraits
              </Pill>
              <Pill backgroundColor="#ffffff" textColor="#4a4a4a">
                ${totalPrice.toFixed(2)}
              </Pill>
            </div>
          </HeaderContainer>

          {/* Templates by Category */}
          <div>
            {templateCategories.map((category) => (
              <TemplateCategorySection
                key={category.name}
                category={category}
                selectedTemplates={selectedTemplates}
                handleTemplateSelect={handleTemplateSelect}
                handleToggleSelectAllInCategory={handleToggleSelectAllInCategory}
                getCategoryNameByTemplateId={getCategoryNameByTemplateId}
                hasDoneFreeOrder={hasDoneFreeOrder}
              />
            ))}
          </div>
        </ContentContainer>

        {/* Bottom Navigation */}
        <BottomNav>
          <NavButton
            primary
            onClick={handleNext}
            aria-label="Proceed to Next Step"
            disabled={selectedTemplates.length === 0}
          >
            Next
          </NavButton>
        </BottomNav>
      </FullscreenContainer>
    </>
  );
};

export default TemplateSelectionPage;
